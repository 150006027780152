import { LoopingConfig } from 'model/questionnaire'
import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'

export const shouldScreenOutBasedOnMatrixLooping = (
  respondentChoices: PersistentRespondentChoice[],
  looping: LoopingConfig[]
) => {
  return looping.some(({ loopEntries, qualifyingLoopResponseOptionIds }) => {
    const idsToLoop = respondentChoices
      .filter(({ responseChoiceIds }) =>
        qualifyingLoopResponseOptionIds?.some((responseOptionId) =>
          responseChoiceIds?.includes(responseOptionId)
        )
      )
      .map(({ questionId }) => questionId)

    return loopEntries
      .filter(({ id }) => idsToLoop.includes(id))
      .every(({ isQuotaFull }) => isQuotaFull)
  })
}
