import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { getRespondentChoiceKey } from './constants'

const getPersistedRespondentChoices = (
  respondentId: string,
  surveyId: string
) => {
  const persistedRespondentChoicesString =
    window.localStorage.getItem(
      getRespondentChoiceKey(respondentId, surveyId)
    ) || ''

  const persistedRespondentChoices: PersistentRespondentChoice[] =
    persistedRespondentChoicesString
      ? JSON.parse(persistedRespondentChoicesString)
      : []

  return persistedRespondentChoices
}

export default getPersistedRespondentChoices
