import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { getRespondentChoiceKey } from './constants'
import getPersistedRespondentChoices from './getPersistedRespondentChoices'

const persistenRespondentChoices = (
  respondentId: string,
  surveyId: string,
  respondentChoice: PersistentRespondentChoice
) => {
  const currentPersistentRespondentChoices = getPersistedRespondentChoices(
    respondentId,
    surveyId
  )

  currentPersistentRespondentChoices.push({
    questionId: respondentChoice.questionId,
    matrixTitleId: respondentChoice.matrixTitleId,
    responseChoiceIds: respondentChoice.responseChoiceIds
  })

  window.localStorage.setItem(
    getRespondentChoiceKey(respondentId, surveyId),
    JSON.stringify(currentPersistentRespondentChoices)
  )
}

export default persistenRespondentChoices
