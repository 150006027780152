import { useContext } from 'react'
import { PfmContext, PfmData } from 'containers/PageFlowManager/PageFlowManager'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { useQuestionnaireParams } from 'hooks/useQuestionnaireParams'
import { EntryType } from 'model/common'
import { QuestionItem, QuestionTypes, SettingCodes } from 'model/questionnaire'
import { ROUTE_TO_END_SURVEY_NUMBER } from 'utils/constants'
import { isSettingEnabled } from 'utils/question'
import getPersistedRespondentChoices from 'utils/getPersistedRespondentChoices'
import { getEntryId } from 'utils/removeRespondentChoiceFromLocalstorage'
import {
  shouldHardDisqualify,
  shouldScreenOut,
  shouldScreenOutBasedOnMatrixLooping
} from './QuestionFlowManager.utils'

export const useQuestionFlowManager = () => {
  const mandatoryParams = useQuestionnaireParams()
  const isPreviewMode = mandatoryParams?.preview
  const {
    respondentProgress: [respondentProgress, setRespondentProgress],
    renderedQuestionnaire: [data]
  } = useContext<AppState>(AppStateContext)
  const { next } = useContext<PfmData>(PfmContext)

  const setProgress = (nextEntryPosition: number) => {
    if (respondentProgress) {
      const newRespondentProgress = { ...respondentProgress }
      const isEndOfSurvey =
        nextEntryPosition === data?.questionnaire.entries.length ||
        nextEntryPosition === ROUTE_TO_END_SURVEY_NUMBER
      if (isEndOfSurvey) {
        newRespondentProgress.isCompleted = true
        next()
      } else {
        newRespondentProgress.previousEntryPosition =
          respondentProgress.currentEntryPosition
        newRespondentProgress.currentEntryPosition = nextEntryPosition
      }
      setRespondentProgress(newRespondentProgress)
    }
  }

  // TODO: move this to a hook
  const handleRouting = (selectedPositions?: number[]): boolean => {
    if (respondentProgress && data && selectedPositions) {
      const currentQuestion =
        data.questionnaire.entries[respondentProgress.currentEntryPosition]
      if (currentQuestion.entryType === EntryType.QuestionEntryType) {
        const questionItem = currentQuestion.entryItem as QuestionItem

        const responseOptions = questionItem.responseOptions?.filter((ro) =>
          selectedPositions.includes(ro.position)
        )

        const targetNumbers = responseOptions
          ?.map((responseOption) => responseOption.route?.targetNumber)
          .filter((routeTargetNumber) => routeTargetNumber !== undefined)
        const targetNumber = targetNumbers && targetNumbers[0] // TODO: This does not address if there are multiple routes on a multiple question

        if (!targetNumber) {
          return false
        }

        if (targetNumber === ROUTE_TO_END_SURVEY_NUMBER) {
          setProgress(targetNumber)
          return true
        }

        const filteredQuestionnaireEntries =
          data?.questionnaire.entries?.filter(
            (entry) =>
              (entry.entryItem as QuestionItem).questionTypeCode !==
                QuestionTypes.StandardAudience &&
              (entry.entryItem as QuestionItem).questionTypeCode !==
                QuestionTypes.CustomAudience
          )
        const entryToRouteTo = filteredQuestionnaireEntries.find(
          (entry) => entry.entryNumber === targetNumber
        )

        if (entryToRouteTo) {
          setProgress(entryToRouteTo.renderedEntryPosition)
          return true
        }
      }
    }
    return false
  }

  const isScreenOut = (selectedPositions: number[]): boolean => {
    if (respondentProgress && data) {
      const currentQuestion =
        data.questionnaire.entries[respondentProgress.currentEntryPosition]
      return shouldScreenOut(
        mandatoryParams.respondentId,
        mandatoryParams.surveyId,
        currentQuestion,
        selectedPositions
      )
    }

    return false
  }

  const handleScreenOut = (selectedPositions: number[]): boolean => {
    const screenOut = isScreenOut(selectedPositions)

    if (screenOut && respondentProgress) {
      const newRespondentProgress = { ...respondentProgress }
      newRespondentProgress.isScreened = true
      setRespondentProgress(newRespondentProgress)
      next()
      return true
    }

    return false
  }

  const isQuotaFull = (selectedPosition: number): boolean => {
    if (respondentProgress && data) {
      const currentQuestion = data.questionnaire.entries[
        respondentProgress.currentEntryPosition
      ].entryItem as QuestionItem
      if (
        currentQuestion.questionTypeCode === QuestionTypes.StandardAudience ||
        currentQuestion.questionTypeCode === QuestionTypes.CustomAudience
      ) {
        const responseOption = currentQuestion.responseOptions?.find(
          (ro) => ro.position === selectedPosition
        )
        const quota = data.quotaStatus.quotas.find(
          (quota) =>
            quota.key.questionId === currentQuestion.question.questionId &&
            quota.key.responseOptionId ===
              responseOption?.option.responseOptionId
        )
        const quotasEnabled = isSettingEnabled(
          currentQuestion.settingValues,
          SettingCodes.Quotas
        )
        if (responseOption && quota?.isFull && quotasEnabled) {
          return true
        }
      }
    }

    return false
  }

  const isMatrixLoopingQuotaFull = (): boolean => {
    if (respondentProgress && data) {
      const currentQuestion =
        data.questionnaire.entries[respondentProgress.currentEntryPosition]
      const { looping } = currentQuestion

      if (currentQuestion.entryType === EntryType.MatrixEntryType && looping) {
        const respondentChoices = getPersistedRespondentChoices(
          mandatoryParams.respondentId,
          mandatoryParams.surveyId
        ).filter(
          ({ matrixTitleId }) => matrixTitleId === getEntryId(currentQuestion)
        )
        return shouldScreenOutBasedOnMatrixLooping(respondentChoices, looping)
      }
    }

    return false
  }

  const handleQuotaFull = (selectedPosition: number): boolean => {
    const quotaFull = isQuotaFull(selectedPosition)

    // Ignore isQuotaFull when on preview mode
    if (quotaFull && respondentProgress && !isPreviewMode) {
      const newRespondentProgress = { ...respondentProgress }
      newRespondentProgress.isQuotaFull = true
      setRespondentProgress(newRespondentProgress)
      next()
      return true
    }

    return false
  }

  const handleQualityTerminate = (selectedPositions: number[]): boolean => {
    if (respondentProgress && data) {
      const currentQuestion = data.questionnaire.entries[
        respondentProgress.currentEntryPosition
      ].entryItem as QuestionItem
      if (
        shouldHardDisqualify(currentQuestion, selectedPositions) ||
        (currentQuestion.questionTypeCode === QuestionTypes.StandardAudience &&
          (isQuotaFull(selectedPositions[0]) || isScreenOut(selectedPositions)))
      ) {
        const newRespondentProgress = { ...respondentProgress }
        newRespondentProgress.isQualityTerminated = true
        setRespondentProgress(newRespondentProgress)
        next()
        return true
      }
    }

    return false
  }

  const handleMatrixLoopingQuotaFull = () => {
    const quotaFull = isMatrixLoopingQuotaFull()
    // Ignore isQuotaFull when on preview mode
    if (quotaFull && respondentProgress && !isPreviewMode) {
      const newRespondentProgress = { ...respondentProgress }
      newRespondentProgress.isQuotaFull = true
      setRespondentProgress(newRespondentProgress)
      next()
      return true
    }

    return false
  }

  return {
    setProgress,
    handleRouting,
    handleScreenOut,
    handleQuotaFull,
    handleQualityTerminate,
    handleMatrixLoopingQuotaFull
  }
}
