import { useOptionalQuestionnaireParams } from 'hooks/useQuestionnaireParams'
import useRenderedQuestionnaire from 'hooks/useRenderedQuestionnaire'
import useRespondentProgress from 'hooks/useRespondentProgress'
import { RenderedQuestionnaire } from 'model/questionnaire'
import { RespondentProgress } from 'model/respondentProgress'
import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import { useMount, useUpdateEffect } from 'react-use'
import { noContextMethod } from 'utils/context'
import { clearQuestionsHiddenByDisplayLogic } from 'utils/hiddenByDisplayLogic'
import { clearQuestionsHiddenByLooping } from 'utils/hiddenByLooping'
import { persistedFreeTextChoicesByQuestionId } from 'utils/persistedFreeTextChoicesByQuestionId'
import { clearResponseChoices } from 'utils/removeRespondentChoiceFromLocalstorage'

const clearRespondentDataFromLocalStorage = (
  respondentId: string,
  surveyId: string
) => {
  clearQuestionsHiddenByDisplayLogic(respondentId, surveyId)
  clearResponseChoices(respondentId, surveyId)
  persistedFreeTextChoicesByQuestionId.clear(respondentId, surveyId)
  clearQuestionsHiddenByLooping(respondentId, surveyId)
}

export interface AppState {
  respondentProgress: [
    value: RespondentProgress | undefined,
    set: (newVal: RespondentProgress) => void
  ]
  renderedQuestionnaire: [
    data: RenderedQuestionnaire | undefined,
    loading: boolean,
    error: boolean
  ]
}

export const AppStateContext = React.createContext<AppState>({
  respondentProgress: [undefined, noContextMethod],
  renderedQuestionnaire: [undefined, false, false]
})

const AppStateProvider = ({ children }: PropsWithChildren) => {
  const { renderedQuestionnaire, loading, error } = useRenderedQuestionnaire()
  const questionnaireParams = useOptionalQuestionnaireParams()
  const { respondentProgress, setRespondentProgress } = useRespondentProgress()
  const isPreview = !!questionnaireParams?.preview
  const isFirstQuestion = respondentProgress?.currentEntryPosition === 0

  const getStartRespondentProgress = useCallback(
    (termsAgreed: boolean): RespondentProgress | undefined => {
      if (!questionnaireParams) {
        // TODO: log to sentry
        return undefined
      }

      const { surveyId, respondentId } = questionnaireParams

      return {
        currentEntryPosition: 0,
        previousEntryPosition: -1,
        isCompleted: false,
        isQuotaFull: false,
        isScreened: false,
        isQualityTerminated: false,
        isTermsAgreed: termsAgreed,
        questionnaireLen:
          renderedQuestionnaire?.questionnaire.entries?.length || 0,
        respondentId,
        surveyId
      }
    },
    [questionnaireParams, renderedQuestionnaire]
  )

  // always clear local storage for preview on mount (i.e. page refresh)
  useMount(() => {
    const progress = getStartRespondentProgress(true)

    if (!progress) {
      return
    }

    if (isPreview) {
      clearRespondentDataFromLocalStorage(
        questionnaireParams.respondentId,
        questionnaireParams.surveyId
      )
      setRespondentProgress(progress)
    }
  })

  // clear local storage for non-preview if it is beginning of the survey; otherwise, local storage data should remain unchanged in order to allow respondent keep the progress even after page reload
  useUpdateEffect(() => {
    if (!isPreview && isFirstQuestion && questionnaireParams) {
      clearRespondentDataFromLocalStorage(
        questionnaireParams.respondentId,
        questionnaireParams.surveyId
      )
    }
  }, [isFirstQuestion, isPreview, questionnaireParams])

  if (
    renderedQuestionnaire &&
    (!respondentProgress ||
      respondentProgress?.surveyId !== renderedQuestionnaire?.surveyId) &&
    questionnaireParams
  ) {
    const progress = getStartRespondentProgress(false)
    if (progress) {
      setRespondentProgress(progress)
    }
  }

  const contextValue: AppState = useMemo(
    () => ({
      respondentProgress: [respondentProgress, setRespondentProgress],
      renderedQuestionnaire: [renderedQuestionnaire, loading, error]
    }),
    [
      error,
      loading,
      renderedQuestionnaire,
      respondentProgress,
      setRespondentProgress
    ]
  )

  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  )
}

export default AppStateProvider
