import { datadogLogs } from '@datadog/browser-logs'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { RespondentProgress } from 'model/respondentProgress'
import { RespondentEvent, RespondentStatus } from 'model/respondentStatus'
import { useContext } from 'react'
import { RESPONDENT_STATUS_API_PATH } from 'utils/constants'
import { getNowTimeString, getUtcOffset } from 'utils/datetime'
import { apiUrlEndpoint } from 'utils/helper'
import getPersistedRespondentChoices from 'utils/getPersistedRespondentChoices'
import { useQuestionnaireParams } from './useQuestionnaireParams'
import { usePostCallbackWithRetry } from './usePostCallback'

const endpoint = `${apiUrlEndpoint}${RESPONDENT_STATUS_API_PATH}`

const getRespondentStatus: (progress: RespondentProgress) => RespondentEvent = (
  progress
) => {
  if (progress.isCompleted) return RespondentEvent.Complete
  if (progress.isQualityTerminated) return RespondentEvent.QualityTerminate
  if (progress.isQuotaFull) return RespondentEvent.QuotaFull
  if (progress.isScreened) return RespondentEvent.ScreenOut

  return RespondentEvent.NotFound
}

const useSendRespondentStatus = () => {
  const mandatoryQueryParams = useQuestionnaireParams()

  const {
    respondentProgress: [progress]
  } = useContext<AppState>(AppStateContext)
  const sendRespondentStatusInternal =
    usePostCallbackWithRetry<RespondentStatus>()

  const sendRespondentStatus: () => void = () => {
    if (!mandatoryQueryParams || !progress) {
      // NOTE: should never get here
      datadogLogs.logger.error(
        'Respondent status failed to be sent. Mandatory params missing. Ensure `sid`, `rid` and `ps` are specified. If they are, respondent progress is missing',
        { progress }
      )
      return
    }

    const respondentStatus = getRespondentStatus(progress)

    const payload: RespondentStatus = {
      respondentId: mandatoryQueryParams.respondentId,
      status: respondentStatus,
      surveyId: mandatoryQueryParams.surveyId,
      panelSupplierCode: mandatoryQueryParams.panelSupplier,
      isPreview: mandatoryQueryParams.preview,
      metaData: null,
      submissionDatetime: getNowTimeString(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utcOffset: getUtcOffset()
    }

    const persistedRespondentChoices = getPersistedRespondentChoices(
      mandatoryQueryParams.respondentId,
      mandatoryQueryParams.surveyId
    )

    sendRespondentStatusInternal(payload, endpoint)
      .then(() => {
        datadogLogs.logger.info(`Respondent status: ${respondentStatus}`, {
          responseChoices: persistedRespondentChoices
        })
      })
      .catch((err) => {
        datadogLogs.logger.error(
          'Network Failure: Respondent status failed to be sent',
          {
            responseChoices: persistedRespondentChoices,
            err,
            payload,
            payloadSize: JSON.stringify(payload).length
          }
        )
      })
  }

  return sendRespondentStatus
}

export default useSendRespondentStatus
