import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, Logo } from '@focaldata/cin-ui-components'
import { PfmContext, PfmData } from 'containers/PageFlowManager/PageFlowManager'
import useSendRespondentInfo from 'hooks/useSendRespondentInfo'
import InfoPageLayout from 'layouts/InfoPageLayout/InfoPageLayout'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const InfoPage: React.FC = () => {
  const { next } = useContext<PfmData>(PfmContext)
  const sendRespondentInfo = useSendRespondentInfo()

  const showRecaptchaBranding = !!process.env.REACT_APP_RECAPTCHA_KEY
  const [recaptchaToken, setRecaptchaToken] = useState<string>()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha('login')

    if (token) {
      setRecaptchaToken(token)
    }
  }, [executeRecaptcha])

  const handleClick: () => void = () => {
    sendRespondentInfo(recaptchaToken)

    next()
  }

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  return (
    <InfoPageLayout
      visual={<Logo width={180} />}
      title={
        <FormattedMessage
          id="infoPage_welcome"
          defaultMessage="Welcome to your questionnaire brought to you by focaldata."
        />
      }
      subtitle={
        <FormattedMessage
          id="infoPage_pleaseClick"
          defaultMessage="Please click the button below to get started. All answers will be confidential."
        />
      }
      action={
        <Button onClick={handleClick}>
          <FormattedMessage
            id="infoPage_getStarted"
            defaultMessage="Get started"
          />
        </Button>
      }
      showRecaptchaBranding={showRecaptchaBranding}
    />
  )
}

export default InfoPage
