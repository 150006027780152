import { AppState, AppStateContext } from 'containers/State/AppState'
import { QuestionKind, QuestionnaireEntry } from 'model/questionnaire'
import { useContext } from 'react'
import { getEntriesHiddenByLooping } from '../utils/hiddenByLooping'
import { useQuestionnaireParams } from './useQuestionnaireParams'

const useEntryProgress = (entry: QuestionnaireEntry) => {
  const params = useQuestionnaireParams()
  const {
    renderedQuestionnaire: [data]
  } = useContext<AppState>(AppStateContext)

  const hiddenByLooping = new Set(
    getEntriesHiddenByLooping(params.respondentId, params.surveyId)
  )

  const entries = [...(data?.questionnaire.entries ?? [])].sort(
    (a, b) => a.renderedEntryPosition - b.renderedEntryPosition
  )

  let currentEntryRenderedPosition: number | null = null
  let entryPositionCounter = 0

  for (let i = 0; i < entries.length; i += 1) {
    const entryToCheck = entries[i]
    if (
      entryToCheck.entryNumber === entry.entryNumber &&
      entryToCheck.questionKind === entry.questionKind
    ) {
      currentEntryRenderedPosition = entryPositionCounter
    }
    if (
      entryToCheck.questionKind === QuestionKind.AudienceKind ||
      !hiddenByLooping.has(entryToCheck.entryNumber)
    ) {
      entryPositionCounter += 1
    }
  }

  return {
    current: currentEntryRenderedPosition ?? entry.renderedEntryPosition,
    total: entryPositionCounter
  }
}

export default useEntryProgress
