import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { LoopingConfig, QuestionnaireEntry } from 'model/questionnaire'

export const getHiddenLoopingEntriesKey = (
  respondentId: string,
  surveyId: string
) => `${respondentId}.${surveyId}.hiddenLoopingEntries`

export const getEntriesToHideByLooping = (
  looping: LoopingConfig[],
  respondentChoices: PersistentRespondentChoice[]
): number[] => {
  const entriesHiddenByLooping: number[] = []
  looping.forEach(
    ({
      loopEntries,
      qualifyingLoopResponseOptionIds, // for multi-select questions this will contain all the response option ids for the question
      maxIteration
    }: LoopingConfig) => {
      // optionsToLoop will contain either responseChoiceIds (multi-select question) or questionIds (matrix rows)
      const optionsToLoop = respondentChoices
        .filter(
          ({ responseChoiceIds }) =>
            qualifyingLoopResponseOptionIds?.filter((r) =>
              responseChoiceIds?.includes(r)
            ).length > 0
        )
        .flatMap((persisted) =>
          persisted.matrixTitleId
            ? persisted.questionId
            : persisted.responseChoiceIds
        )
      // get up to N ranked responses selected by respondent where N - max number of configured iterations
      const rankedResponseOptionIds = loopEntries
        .filter(({ isQuotaFull }) => !isQuotaFull)
        .sort((a, b) => a.priorityRank - b.priorityRank)
        .map(({ id }) => id)
      const responseOptionIdsToShow = rankedResponseOptionIds
        .filter((rankedResponseOptionId) =>
          optionsToLoop.includes(rankedResponseOptionId)
        )
        .slice(0, maxIteration)

      // find loops which should be skipped and get entry numbers for each of them
      const entriesHiddenByLoopingSlice = loopEntries
        .filter(({ id }) => {
          return !responseOptionIdsToShow.includes(id)
        })
        .flatMap(({ entryNumbers }) => entryNumbers)

      entriesHiddenByLooping.push(...entriesHiddenByLoopingSlice)
    }
  )

  return entriesHiddenByLooping
}

export const getEntriesHiddenByLooping = (
  respondentId: string,
  surveyId: string
): number[] => {
  const hiddenEntriesStorageData = window.localStorage.getItem(
    getHiddenLoopingEntriesKey(respondentId, surveyId)
  )

  return hiddenEntriesStorageData ? JSON.parse(hiddenEntriesStorageData) : []
}

export const addEntriesHiddenByLooping = (
  respondentId: string,
  surveyId: string,
  entriesToHide: number[]
): number[] => {
  const allEntriesHiddenByLooping = Array.from(
    new Set(
      getEntriesHiddenByLooping(respondentId, surveyId).concat(entriesToHide)
    )
  )

  window.localStorage.setItem(
    getHiddenLoopingEntriesKey(respondentId, surveyId),
    JSON.stringify(allEntriesHiddenByLooping)
  )

  return allEntriesHiddenByLooping
}

export const clearQuestionsHiddenByLooping = (
  respondentId: string,
  surveyId: string
) => {
  window.localStorage.removeItem(
    getHiddenLoopingEntriesKey(respondentId, surveyId)
  )
}

export const isEntryHiddenByLooping = (
  respondentId: string,
  surveyId: string,
  entryNumber: number
): boolean => {
  return getEntriesHiddenByLooping(respondentId, surveyId).includes(entryNumber)
}

export const clearQuestionsHiddenByLoopingByEntry = (
  respondentId: string,
  surveyId: string,
  entry: QuestionnaireEntry
) => {
  const entriesHiddenByLooping = getEntriesHiddenByLooping(
    respondentId,
    surveyId
  )

  if (entry.looping) {
    const entriesToRemove = new Set(
      getEntriesToHideByLooping(entry.looping, [])
    )

    const difference = entriesHiddenByLooping.filter(
      (e) => !entriesToRemove.has(e)
    )

    window.localStorage.setItem(
      getHiddenLoopingEntriesKey(respondentId, surveyId),
      JSON.stringify(difference)
    )
  }
}
