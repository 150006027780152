export const getEnvironment: () =>
  | 'development'
  | 'PR'
  | 'staging'
  | 'production' = () => {
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname === 'community.focaldata.com'
  ) {
    return 'production'
  }
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname.includes('community.focaldata.dev')
  ) {
    return 'staging'
  }
  if (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname.includes('fd-respondent-app-pr')
  ) {
    return 'PR'
  }
  return 'development'
}
