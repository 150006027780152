import { useContext } from 'react'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { EntryType } from 'model/common'
import {
  MatrixItem,
  QuestionItem,
  QuestionKind,
  TextCardItem
} from 'model/questionnaire'
import { setHiddenByDisplayLogic } from 'utils/hiddenByDisplayLogic'

import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import useQuestionPiping from 'hooks/useQuestionPiping'
import { getEntriesHiddenByLooping } from '../utils/hiddenByLooping'
import { useQuestionnaireParams } from './useQuestionnaireParams'

export const useNextEntryPosition = (): number => {
  const currentEntry = useQuestionPiping()
  const mandatoryParams = useQuestionnaireParams()
  const {
    renderedQuestionnaire: [data]
  } = useContext<AppState>(AppStateContext)
  const entries = data?.questionnaire.entries ?? []
  const hiddenByLooping = new Set(
    getEntriesHiddenByLooping(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId
    )
  )

  if (!currentEntry) {
    return -1
  }

  let nextEntryPosition = currentEntry.renderedEntryPosition + 1

  const currentEntryIndex = entries.findIndex(
    (e) => e.entryNumber === currentEntry.entryNumber
  )

  for (let i = currentEntryIndex; i < entries.length - 1; i += 1) {
    const currentEntry = entries[i]
    const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      currentEntry.entryItem.questionLogic,
      currentEntry.entryType
    )
    const shouldDisplayEntityBasedOnLooping =
      currentEntry.questionKind === QuestionKind.AudienceKind ||
      !hiddenByLooping.has(currentEntry.entryNumber)

    if (shouldDisplayEntityBasedOnLogic && shouldDisplayEntityBasedOnLooping) {
      break
    }

    switch (currentEntry.entryType) {
      case EntryType.MatrixEntryType:
        setHiddenByDisplayLogic(
          mandatoryParams.respondentId,
          mandatoryParams.surveyId,
          (currentEntry.entryItem as MatrixItem).matrixTitle.matrixTitleId
        )
        break
      case EntryType.QuestionEntryType:
        setHiddenByDisplayLogic(
          mandatoryParams.respondentId,
          mandatoryParams.surveyId,
          (currentEntry.entryItem as QuestionItem).question.questionId
        )
        break
      case EntryType.TextCardEntryType:
        setHiddenByDisplayLogic(
          mandatoryParams.respondentId,
          mandatoryParams.surveyId,
          (currentEntry.entryItem as TextCardItem).textCard.textCardId
        )
        break
      default:
        break
    }

    nextEntryPosition = entries[i + 1].renderedEntryPosition
  }

  return nextEntryPosition
}
