import { datadogLogs } from '@datadog/browser-logs'
import { FdChat } from 'containers/FdChat'
import { AppState, AppStateContext } from 'containers/State/AppState'
import ErrorOverlay from 'controls/ErrorOverlay/ErrorOverlay'
import LoadingOverlay from 'controls/LoadingOverlay/LoadingOverlay'
import { useOptionalQuestionnaireParams } from 'hooks/useQuestionnaireParams'
import React, { PropsWithChildren, useContext } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { noContextMethod } from 'utils/context'

export interface PfmData {
  next: () => void
}

export const PfmContext = React.createContext<PfmData>({
  next: noContextMethod
})

const PageFlowManager = (props: PropsWithChildren<{}>) => {
  const { children }: PropsWithChildren<{}> = props
  const location = useLocation()
  const navigate = useNavigate()
  const questionnaireParams = useOptionalQuestionnaireParams()
  const isPreviewMode = questionnaireParams?.preview
  const {
    respondentProgress: [progress],
    renderedQuestionnaire: [data, loading, error]
  } = useContext<AppState>(AppStateContext)

  const pushHistory: (pathName: string) => void = (pathName) => {
    navigate({
      pathname: pathName,
      search: location.search
    })
  }

  const isSurveyCompleted = progress
    ? progress.isCompleted ||
      progress.isQualityTerminated ||
      progress.isQuotaFull ||
      progress.isScreened
    : false

  // isSurveyFull only matters if the app is not in preview mode
  const isSurveyFull = data && data.isSurveyFull && !isPreviewMode
  const shouldProceedWithFdchat =
    (progress?.isCompleted || data?.questionnaire.entries.length === 0) &&
    data?.redirectToFDChat &&
    progress?.isTermsAgreed

  const getPageRedirect: () => JSX.Element | undefined = () => {
    // if terms have not been agreed, go to info
    if (
      progress &&
      !progress.isTermsAgreed &&
      !isSurveyFull &&
      location.pathname !== '/terms' &&
      location.pathname !== '/info'
    ) {
      return <Navigate replace to={`/info${location.search}`} />
    }

    // if terms have been agreed and survey is not complete, go to questionnaire
    if (
      progress &&
      progress.isTermsAgreed &&
      !isSurveyCompleted &&
      !isSurveyFull &&
      location.pathname !== '/questionnaire'
    ) {
      return <Navigate replace to={`/questionnaire${location.search}`} />
    }

    // if survey is complete, go to complete page
    if (
      progress &&
      (isSurveyCompleted || isSurveyFull) &&
      location.pathname !== '/complete'
    ) {
      datadogLogs.logger.info('getPageRedirect: Forwarding to complete', {
        progress,
        isSurveyCompleted,
        isSurveyFull,
        data
      })
      return <Navigate replace to={`/complete${location.search}`} />
    }

    return undefined
  }

  const handleNext: () => void = () => {
    if (location.pathname === '/info' && data) {
      pushHistory('/terms')
    } else if (location.pathname === '/terms' && progress?.isTermsAgreed) {
      pushHistory('/questionnaire')
    } else if (
      location.pathname === '/questionnaire' &&
      !data?.redirectToFDChat
    ) {
      datadogLogs.logger.info('handleNext: Forwarding to complete', {
        progress,
        isSurveyCompleted,
        isSurveyFull,
        data
      })
      pushHistory('/complete')
    }
  }

  if (loading) {
    return <LoadingOverlay />
  }

  if (error) {
    return <ErrorOverlay />
  }

  if (shouldProceedWithFdchat) {
    return <FdChat />
  }

  const redirectTo = getPageRedirect()

  if (redirectTo) {
    return redirectTo
  }

  return (
    <PfmContext.Provider value={{ next: handleNext }}>
      {children}
    </PfmContext.Provider>
  )
}

export default PageFlowManager
