import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import {
  EntryResponseOption,
  MaskingRuleClause,
  MatrixItem,
  QuestionItem
} from 'model/questionnaire'
import getPersistedRespondentChoices from './getPersistedRespondentChoices'
import { maskingRuleMetByAtLeastOneMaskingKey, getClause } from './maskingLogic'

const notMaskedResponseOptions =
  (
    respondentId: string,
    surveyId: string,
    persistedRespondentChoices: PersistentRespondentChoice[]
  ) =>
  (responseOption: EntryResponseOption): boolean => {
    const isMaskingRuleMet = maskingRuleMetByAtLeastOneMaskingKey(
      respondentId,
      surveyId,
      persistedRespondentChoices
    )

    if (responseOption.maskingRules?.length > 0)
      return getClause(
        responseOption.maskingRules?.filter((_, index) => index === 1)
      ) === MaskingRuleClause.AND
        ? !responseOption.maskingRules.some(
            (maskingRule) => !isMaskingRuleMet(maskingRule)
          )
        : responseOption.maskingRules.some(isMaskingRuleMet)
    return true
  }

const getMaskedResponseOptions = (
  respondentId: string,
  surveyId: string,
  responseOptions: EntryResponseOption[] | undefined
): string[] | undefined => {
  const persistedRespondentChoices = getPersistedRespondentChoices(
    respondentId,
    surveyId
  )
  return responseOptions
    ?.filter(
      (responseOption) =>
        !notMaskedResponseOptions(
          respondentId,
          surveyId,
          persistedRespondentChoices
        )(responseOption)
    )
    .map((option) => option.option.responseOptionId)
}

export const hasMaskingRules = (
  responseOptions?: EntryResponseOption[]
): boolean => {
  return responseOptions?.some((ro) => ro.maskingRules.length > 0) ?? false
}

const getVisibleResponseOptions = <T extends QuestionItem | MatrixItem>(
  respondentId: string,
  surveyId: string,
  entryItem: T
): T => {
  const maskedResponseOptions = getMaskedResponseOptions(
    respondentId,
    surveyId,
    entryItem.responseOptions
  )

  return hasMaskingRules(entryItem.responseOptions)
    ? {
        ...entryItem,
        responseOptions: entryItem.responseOptions?.filter(
          (option) =>
            !maskedResponseOptions?.includes(option.option.responseOptionId)
        )
      }
    : entryItem
}

const entryHasNoNonDefaultResponseOptions = (
  responseOptions: EntryResponseOption[] | undefined
) => {
  return !responseOptions?.some(
    (responseOption) => !responseOption.option.isDefaultOption
  )
}

export { getVisibleResponseOptions, entryHasNoNonDefaultResponseOptions }
