import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  Ranked,
  SelectedRankedResponse,
  SelectedResponse
} from '@focaldata/cin-ui-components'
import {
  CfmData,
  CfmContext
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import {
  EntryResponseOption,
  QuestionItem,
  QuestionnaireEntry,
  SettingCodes
} from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import {
  entryHasNoNonDefaultResponseOptions,
  getVisibleResponseOptions
} from 'utils/responseOptionsMaskingLogic'
import { getSetting } from 'utils/question'
import {
  createResponseOptionChoice,
  createResponseOptionChoiceRanked
} from 'utils/responseChoice'
import { useQuestionTimer } from 'hooks/useQuestionTimer'
import { useQuestionnaireParams } from '../../hooks/useQuestionnaireParams'

interface Props {
  entry: QuestionnaireEntry
}

const getRankedChoiceRequisiteInstructions: (
  requisite: string | undefined,
  responseOptionsNo: number | undefined
) => string = (requisite, responseOptionsNo) =>
  requisite === undefined ||
  (requisite && responseOptionsNo && Number(requisite) >= responseOptionsNo)
    ? 'question_rankedPleaseRankAll'
    : 'question_rankedPleaseRankTop'

const RankedEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as QuestionItem
  const progress = useEntryProgress(entry)
  const timer = useQuestionTimer(entryItem)
  const requisite = getSetting(
    entryItem.settingValues,
    SettingCodes.ChoiceRequisite
  )
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const sendResponseOptionChoices = useSendResponseChoice()
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const mandatoryParams = useQuestionnaireParams()

  const getResponseOptionChoices: (
    selectedResponses: SelectedRankedResponse[] | SelectedResponse
  ) => ResponseOptionChoice[] = (selectedResponses) => {
    if (entryItem.responseOptions) {
      if (Array.isArray(selectedResponses)) {
        const rankedOptions = selectedResponses.map(
          (selectedResponse, index) => {
            const responseOption: EntryResponseOption | undefined =
              entryItem.responseOptions?.find(
                (ro) => ro.position === selectedResponse.selectedPosition
              )
            return createResponseOptionChoiceRanked(
              responseOption,
              selectedResponse,
              index
            )
          }
        )
        const unrankedOptions = entryItem.responseOptions
          ?.filter(
            (ro) =>
              !selectedResponses.some(
                (sr) => sr.selectedPosition === ro.position
              )
          )
          .map((ro) => {
            return createResponseOptionChoiceRanked(ro, undefined, undefined)
          })

        return [...rankedOptions, ...unrankedOptions]
      }

      // Otherwise, user selected a default option
      return (
        entryItem.responseOptions?.map((responseOption) => {
          const selected =
            responseOption.position === selectedResponses.selectedPosition
          return createResponseOptionChoice(
            responseOption,
            selected ? selectedResponses : undefined
          )
        }) || []
      )
    }

    return []
  }

  const handleNext: (
    selectedResponses: SelectedRankedResponse[],
    selectedDefaultOption?: SelectedResponse
  ) => void = (selectedResponses, selectedDefaultOption) => {
    let selectedResponseOptions: SelectedRankedResponse[] | SelectedResponse =
      selectedResponses
    let nextEntryPosition: number[] = []

    if (
      selectedDefaultOption !== undefined &&
      selectedDefaultOption.selectedPosition > -1
    ) {
      selectedResponseOptions = selectedDefaultOption
      nextEntryPosition = [selectedDefaultOption.selectedPosition]
    }

    if (!preview) {
      sendResponseOptionChoices({
        questionId: entryItem.question.questionId,
        entry,
        entryType: entry.entryType,
        renderingDateTime,
        responseChoices: getResponseOptionChoices(selectedResponseOptions)
      })
    }

    getNextEntryPosition(nextEntryPosition)
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    getNextEntryPosition()
  }

  const entryItemWithFilteredResponseOptions = getVisibleResponseOptions(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    entryItem
  )

  if (
    entryHasNoNonDefaultResponseOptions(
      entryItemWithFilteredResponseOptions.responseOptions
    )
  ) {
    getNextEntryPosition()
  }

  const responseOptionsNo =
    entryItemWithFilteredResponseOptions.responseOptions?.length

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: (
      <FormattedMessage
        id={getRankedChoiceRequisiteInstructions(requisite, responseOptionsNo)}
        defaultMessage="Please rank all options"
        values={{ count: requisite }}
      />
    ),
    tapToRemove: (
      <FormattedMessage
        id="question_rankedTapToRemove"
        defaultMessage="TAP TO REMOVE"
      />
    ),
    tapToSelect: (
      <FormattedMessage
        id="question_rankedTapToSelect"
        defaultMessage="TAP TO SELECT"
      />
    ),
    dragToReorder: (
      <FormattedMessage
        id="question_rankedDragToReorder"
        defaultMessage="DRAG TO REORDER"
      />
    )
  }

  return (
    <Ranked
      item={entryItemWithFilteredResponseOptions}
      loc={loc}
      progress={progress}
      timer={timer}
      preview={preview}
      next={{ disabled: false, loading: false, onNext: handleNext }}
      dataCy="response-option"
    />
  )
}

export default RankedEntry
