import { useState } from 'react'
import useQueryParameters from './useQueryParams'

export interface CintQueryParams {
  memberId: string | null
  gender: string | null
  yearOfBirth: number | null
  age: number | null
  panelistId: string | null
  postCode: string | null
  panelId: string | null
  orderNumber: number | null
}

export const useCintQueryParams: () => CintQueryParams = () => {
  const queryParams = useQueryParameters()
  const [state] = useState<CintQueryParams>(() => {
    const memberId: string | null = queryParams.get('mid')
    const gender: string | null = queryParams.get('g')
    const yobString: string | null = queryParams.get('yob')
    const ageString: string | null = queryParams.get('age')
    const panelistId: string | null = queryParams.get('pid')
    const postCode: string | null = queryParams.get('pc')
    const panelId: string | null = queryParams.get('panid')
    const on: string | null = queryParams.get('on')

    return {
      memberId,
      gender,
      yearOfBirth: yobString ? parseInt(yobString, 10) : null,
      age: ageString ? parseInt(ageString, 10) : null,
      panelistId,
      postCode,
      panelId,
      orderNumber: on ? parseInt(on, 10) : null
    }
  })

  return state
}
