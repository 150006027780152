import { RenderedQuestionnaire } from 'model/questionnaire'
import { getEntryId } from './removeRespondentChoiceFromLocalstorage'

export const getDisplayLogicStateKey = (
  respondentId: string,
  surveyId: string
) => `${respondentId}.${surveyId}.displayLogicState`

export const setHiddenByDisplayLogic = (
  respondentId: string,
  surveyId: string,
  questionId: string
) => {
  const questionsHiddenByDisplayLogicString =
    window.localStorage.getItem(
      getDisplayLogicStateKey(respondentId, surveyId)
    ) || ''

  const questionsHiddenByDisplayLogic: string[] =
    questionsHiddenByDisplayLogicString
      ? JSON.parse(questionsHiddenByDisplayLogicString)
      : []

  if (!questionsHiddenByDisplayLogic.includes(questionId))
    questionsHiddenByDisplayLogic.push(questionId)

  window.localStorage.setItem(
    getDisplayLogicStateKey(respondentId, surveyId),
    JSON.stringify(questionsHiddenByDisplayLogic)
  )
}

export const removeHiddenByDisplayLogic = (
  respondentId: string,
  surveyId: string,
  data: RenderedQuestionnaire | undefined,
  entryPosition?: number
) => {
  if (entryPosition !== undefined) {
    const questionsHiddenByDisplayLogicString =
      window.localStorage.getItem(
        getDisplayLogicStateKey(respondentId, surveyId)
      ) || ''

    const questionsHiddenByDisplayLogic: string[] =
      questionsHiddenByDisplayLogicString
        ? JSON.parse(questionsHiddenByDisplayLogicString)
        : []

    const entry = data?.questionnaire?.entries.find(
      (entry) => entry.renderedEntryPosition === entryPosition
    )

    const entryId = getEntryId(entry)

    const newListOfHiddenQuestions = questionsHiddenByDisplayLogic.filter(
      (id) => id !== entryId
    )

    window.localStorage.setItem(
      getDisplayLogicStateKey(respondentId, surveyId),
      JSON.stringify(newListOfHiddenQuestions)
    )
  }
}

export const isQuestionHiddenByDisplayLogic = (
  respondentId: string,
  surveyId: string,
  questionId: string
) => {
  const questionsHiddenByDisplayLogicString =
    window.localStorage.getItem(
      getDisplayLogicStateKey(respondentId, surveyId)
    ) || ''

  const questionsHiddenByDisplayLogic: string[] =
    questionsHiddenByDisplayLogicString
      ? JSON.parse(questionsHiddenByDisplayLogicString)
      : []

  const found = questionsHiddenByDisplayLogic.find((id) => id === questionId)

  return !!found
}

export const clearQuestionsHiddenByDisplayLogic = (
  respondentId: string,
  surveyId: string
) => {
  window.localStorage.removeItem(
    getDisplayLogicStateKey(respondentId, surveyId)
  )
}
