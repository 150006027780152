import InfoPageLayout from 'layouts/InfoPageLayout/InfoPageLayout'
import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Text, TextHighlight, TextWeight } from '@focaldata/cin-ui-components'
import checkSrc from 'assets/check.svg'
import { useQuestionnaireParams } from 'hooks/useQuestionnaireParams'
import useSendRespondentStatus from 'hooks/useSendRespondentStatus'
import { AppState, AppStateContext } from 'containers/State/AppState'

const TIMER_START_VALUE = 3

const CompletePage: React.FC = () => {
  const [timer, setTimer] = useState<number>(TIMER_START_VALUE)
  const mandatoryQueryParams = useQuestionnaireParams()
  const {
    respondentProgress: [progress]
  } = useContext<AppState>(AppStateContext)
  const sendRespondentStatus = useSendRespondentStatus()
  const isCompleted = progress?.isCompleted
  const isScreenOut = progress?.isScreened
  const CINT_REDIRECT_URL = `${process.env.REACT_APP_CINT_REDIRECT_URL}/${mandatoryQueryParams?.respondentId}`
  const PROLIFIC_REDIRECT_URL = `${
    process.env.REACT_APP_PROLIFIC_REDIRECT_URL
  }?cc=${
    isScreenOut
      ? process.env.REACT_APP_PROLIFIC_SCREEN_OUT_CODE
      : process.env.REACT_APP_PROLIFIC_COMPLETE_CODE
  }`
  const isCint = mandatoryQueryParams?.panelSupplier === 'cint'
  const isProlific = mandatoryQueryParams?.panelSupplier === 'prolific'

  useEffect(() => {
    if (!mandatoryQueryParams?.preview) {
      if (timer === TIMER_START_VALUE) {
        sendRespondentStatus()
      }

      // if the panel supplier is cint or prolific, redirect after 3 seconds
      if (isCint || isProlific) {
        if (timer > 0) {
          setTimeout(() => {
            setTimer(timer - 1)
          }, 1000)
        } else {
          window.location.assign(
            isCint ? CINT_REDIRECT_URL : PROLIFIC_REDIRECT_URL
          )
        }
      }
    }
  })

  const getSubtitle = () => {
    if (isCompleted) return 'completePage_weAppreciate'
    if (isCint || isProlific) return 'completePage_unfortunately'
    return 'completePage_unfortunately_close_page'
  }

  const locIds = new Map<string, string>([
    ['title', isCompleted ? 'completePage_thanks' : 'completePage_thanks2'],
    ['subtitle', getSubtitle()],
    [
      'action',
      mandatoryQueryParams?.preview
        ? 'completePage_preview'
        : 'completePage_redirecting'
    ]
  ])

  return (
    <InfoPageLayout
      visual={<img src={checkSrc} alt="check icon" />}
      title={
        <FormattedMessage
          id={locIds.get('title')}
          defaultMessage="Thanks for completing our survey!"
        />
      }
      subtitle={
        <FormattedMessage
          id={locIds.get('subtitle')}
          defaultMessage={`We appreciate you taking the time to answer the survey. ${
            isCint
              ? 'To officially complete this survey, wait until you are redirected.'
              : 'You may close this page.'
          }`}
        />
      }
      action={
        isCint ? (
          <Text highlight={TextHighlight.Emphasis} weight={TextWeight.SemiBold}>
            <FormattedMessage
              id={locIds.get('action')}
              defaultMessage="Redirecting to Cint in {count} seconds"
              values={{ count: timer }}
            />
          </Text>
        ) : undefined
      }
    />
  )
}

export default CompletePage
