import { RespondentProgress } from 'model/respondentProgress'
import { useCallback, useState } from 'react'
import { useOptionalQuestionnaireParams } from './useQuestionnaireParams'

export const getRespondentProgressKey = (
  respondentId: string,
  surveyId: string
) => `${respondentId}.${surveyId}.respondentProgress`

const useRespondentProgress = () => {
  const questionnaireParams = useOptionalQuestionnaireParams()

  const [respondentProgress, setRespondentProgress] = useState(() => {
    const respondentProgressString = questionnaireParams
      ? window.localStorage.getItem(
          getRespondentProgressKey(
            questionnaireParams.respondentId,
            questionnaireParams.surveyId
          )
        )
      : null

    return respondentProgressString
      ? (JSON.parse(respondentProgressString) as RespondentProgress)
      : undefined
  })

  const setRespondentProgressInternal = useCallback(
    (newProgress: RespondentProgress) => {
      if (questionnaireParams) {
        window.localStorage.setItem(
          getRespondentProgressKey(
            questionnaireParams.respondentId,
            questionnaireParams.surveyId
          ),
          JSON.stringify(newProgress)
        )
      }
      setRespondentProgress(newProgress)
    },
    [questionnaireParams]
  )

  return {
    respondentProgress,
    setRespondentProgress: setRespondentProgressInternal
  }
}

export default useRespondentProgress
