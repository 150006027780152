export interface RespondentStatus {
  surveyId: string
  panelSupplierCode: string
  respondentId: string
  isPreview: boolean
  status: RespondentEvent
  metaData: string | null
  submissionDatetime: string
  timeZone: string
  utcOffset: string
}

export enum RespondentEvent {
  Start = 'Start',
  Complete = 'Complete',
  QualityTerminate = 'QualityTerminate',
  QuotaFull = 'QuotaFull',
  ScreenOut = 'ScreenOut',
  NotFound = 'NotFound'
}
