import React from 'react'
import {
  Grid,
  Text,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import useStyles from './TermsPageLayout.styles'

interface Props {
  title?: JSX.Element | string
  topCheckbox?: JSX.Element | string
  topAction?: JSX.Element | string
  terms?: JSX.Element | string
  bottomCheckbox?: JSX.Element | string
  bottomAction?: JSX.Element | string
}

const TermsPageLayout: React.FC<Props> = (props: Props) => {
  const {
    title,
    terms,
    topCheckbox,
    topAction,
    bottomCheckbox,
    bottomAction
  }: Props = props
  const { classes } = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      className={classes.container}
    >
      <Text
        size={TextSize.ml}
        weight={TextWeight.SemiBold}
        className={classes.title}
      >
        {title}
      </Text>
      <Grid item>{topCheckbox}</Grid>
      <Grid item className={classes.action}>
        {topAction}
      </Grid>
      <div dir="ltr">
        <Text highlight={TextHighlight.Background} className={classes.terms}>
          {terms}
        </Text>
      </div>
      <Grid item>{bottomCheckbox}</Grid>
      <Grid item className={classes.action}>
        {bottomAction}
      </Grid>
    </Grid>
  )
}

export default TermsPageLayout
