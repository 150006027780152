import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { TextInstructions } from '@focaldata/cin-ui-components'

import {
  CfmContext,
  CfmData
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import { QuestionnaireEntry, TextCardItem } from 'model/questionnaire'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { useQuestionTimer } from 'hooks/useQuestionTimer'
import { useQuestionnaireParams } from '../../hooks/useQuestionnaireParams'

interface Props {
  entry: QuestionnaireEntry
}

const TextInstructionsEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const item = entry.entryItem as TextCardItem
  const progress = useEntryProgress(entry)
  const timer = useQuestionTimer(item)
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const mandatoryParams = useQuestionnaireParams()

  const handleNext: () => void = () => {
    getNextEntryPosition()
  }

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: item.textCard.body
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    item.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    getNextEntryPosition()
  }

  return (
    <TextInstructions
      item={item}
      loc={loc}
      preview={preview}
      progress={progress}
      timer={timer}
      next={{ disabled: false, loading: false, onNext: handleNext }}
    />
  )
}

export default TextInstructionsEntry
