import { SelectedResponse } from '@focaldata/cin-ui-components'
import { MatrixItem, QuestionItem } from 'model/questionnaire'

export const getResponseChoiceIds = (
  entryItem: QuestionItem | MatrixItem,
  selectedResponses: SelectedResponse[]
): string[] => {
  const selectedPositions = new Set(
    selectedResponses.map((response) => response.selectedPosition)
  )
  const responseChoiceIds: string[] = []

  entryItem.responseOptions?.forEach((responseOption) => {
    const isOptionSelected = selectedPositions.has(responseOption.position)
    if (isOptionSelected) {
      responseChoiceIds.push(responseOption.option.responseOptionId)
    }
  })

  return responseChoiceIds
}
