import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EntrySettingValue,
  MatrixItem,
  QuestionItem,
  SettingCodes,
  TextCardItem
} from '../model/questionnaire'
import { useCountdown } from './useCountdown'
import en from '../intl/copy/en.json'

interface TimerData {
  countdown: number
  message: JSX.Element | string
}

const getQuestionTimerSeconds = (
  settingValues?: EntrySettingValue[]
): number => {
  const questionTimerSettingValue = settingValues?.find(
    ({ code }) => code === SettingCodes.QuestionTimer
  )?.value

  return questionTimerSettingValue ? Number(questionTimerSettingValue) : 0
}

export const useQuestionTimer = (
  entryItem: QuestionItem | MatrixItem | TextCardItem
): TimerData | undefined => {
  const timerValue = getQuestionTimerSeconds(entryItem.settingValues)
  const { countdown, startCountdown } = useCountdown(timerValue)

  useEffect(() => {
    startCountdown()
  }, [entryItem, startCountdown])

  if (!timerValue) {
    return undefined
  }

  return {
    countdown,
    message: (
      <FormattedMessage
        id="question_timerMessage"
        values={{ timerValue }}
        defaultMessage={en.question_timerMessage}
      />
    )
  }
}
