import React from 'react'
import Entry from 'controls/entries/Entry'
import ErrorOverlay from 'controls/ErrorOverlay/ErrorOverlay'
import useQuestionPiping from 'hooks/useQuestionPiping'

const QuestionnairePage: React.FC = () => {
  const currentEntry = useQuestionPiping()

  if (!currentEntry) {
    // TODO: log to sentry, this should never happen
    return <ErrorOverlay />
  }

  return (
    <Entry
      entry={currentEntry}
      // This ensures the state of any inputs/iframes are reset when changing
      // question – doing it here is safest as we don't need to handle it for
      // each individual question type
      key={currentEntry.entryPosition}
    />
  )
}

export default QuestionnairePage
