import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuestionnaireParams } from 'hooks/useQuestionnaireParams'
import useStyles from './FdChat.styles'

export const FdChat: React.FC = () => {
  const [fdchatLoaded, setFdchatLoaded] = useState(false)
  const mandatoryParams = useQuestionnaireParams()
  const location = useLocation()
  const { classes, cx: classNames } = useStyles()
  const { surveyId, respondentId } = mandatoryParams ?? {}
  const searchParams = new URLSearchParams(location.search)
  searchParams.set('hybrid', 'true')
  const fdchatUrl = `${process.env.REACT_APP_FDCHAT_DOMAIN}/${surveyId}/${respondentId}?${searchParams}`

  return (
    <>
      <div
        data-testid="spinner"
        className={classNames('cover', classes.spinnerWrapper, {
          fdchatLoading: !fdchatLoaded
        })}
      >
        <div className="spinner">
          <div className="static" />
        </div>
      </div>
      <iframe
        data-testid="iframe"
        title="fdchat"
        onLoad={() => setFdchatLoaded(true)}
        height="100%"
        width="100%"
        className={classNames(classes.iframe, { fdchatLoading: !fdchatLoaded })}
        src={fdchatUrl}
      />
    </>
  )
}
