import { QuestionItem, QuestionTypes, SettingCodes } from 'model/questionnaire'
import { isSettingEnabled } from '../../../utils/question'

export const shouldHardDisqualify = (
  currentQuestion: QuestionItem,
  selectedResponseOptionPositions: number[]
): boolean => {
  const isHardDisqualifying = isSettingEnabled(
    currentQuestion.settingValues ?? [],
    SettingCodes.HardDisqualification
  )
  if (
    isHardDisqualifying &&
    (currentQuestion.questionTypeCode === QuestionTypes.StandardAudience ||
      currentQuestion.questionTypeCode === QuestionTypes.CustomAudience)
  ) {
    const selectedPositionsSet = new Set(selectedResponseOptionPositions)

    return !!currentQuestion.responseOptions?.some(
      (responseOption) =>
        selectedPositionsSet.has(responseOption.position) &&
        !responseOption.option.isQualifying
    )
  }

  return false
}
