import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { MaskingRuleClause, MatrixItem, MatrixRow } from 'model/questionnaire'
import getPersistedRespondentChoices from './getPersistedRespondentChoices'
import { setHiddenByDisplayLogic } from './hiddenByDisplayLogic'
import { maskingRuleMetByAtLeastOneMaskingKey, getClause } from './maskingLogic'

const notMaskedMatrixRows =
  (
    respondentId: string,
    surveyId: string,
    persistedRespondentChoices: PersistentRespondentChoice[]
  ) =>
  (matrixRow: MatrixRow): boolean => {
    const isMaskingRuleMet = maskingRuleMetByAtLeastOneMaskingKey(
      respondentId,
      surveyId,
      persistedRespondentChoices
    )

    if (matrixRow.maskingRules?.length > 0)
      return getClause(
        matrixRow.maskingRules?.filter((_, index) => index === 1)
      ) === MaskingRuleClause.AND
        ? !matrixRow.maskingRules.some(
            (maskingRule) => !isMaskingRuleMet(maskingRule)
          )
        : matrixRow.maskingRules.some(isMaskingRuleMet)
    return true
  }

const getMaskedMatrixRows = (
  respondentId: string,
  surveyId: string,
  matrixRows: MatrixRow[] | undefined
): string[] | undefined => {
  const persistedRespondentChoices = getPersistedRespondentChoices(
    respondentId,
    surveyId
  )
  return matrixRows
    ?.filter(
      (row) =>
        !notMaskedMatrixRows(
          respondentId,
          surveyId,
          persistedRespondentChoices
        )(row)
    )
    .map((row) => row.question.questionId)
}

export const hasMaskingRules = (row?: MatrixRow[]): boolean => {
  return row?.some((row) => row.maskingRules.length > 0) ?? false
}

const getVisibleMatrixRows = (
  respondentId: string,
  surveyId: string,
  entryItem: MatrixItem
): MatrixItem => {
  if (hasMaskingRules(entryItem.matrixRows)) {
    const maskedMatrixRows = getMaskedMatrixRows(
      respondentId,
      surveyId,
      entryItem.matrixRows
    )
    maskedMatrixRows?.forEach((maskedMatrixRowId) =>
      setHiddenByDisplayLogic(respondentId, surveyId, maskedMatrixRowId)
    )

    return {
      ...entryItem,
      matrixRows: entryItem.matrixRows?.filter(
        (row) => !maskedMatrixRows?.includes(row.question.questionId)
      )
    }
  }
  return entryItem
}

const entryHasNoMatrixRows = (matrixRows: MatrixRow[] | undefined) => {
  return matrixRows?.length === 0
}

export { getVisibleMatrixRows, entryHasNoMatrixRows }
