import React from 'react'
import {
  Grid,
  Text,
  TextAlign,
  TextHighlight,
  TextSize,
  TextWeight
} from '@focaldata/cin-ui-components'
import useStyles from './InfoPageLayout.styles'

interface Props {
  title?: JSX.Element | string
  subtitle?: JSX.Element | string
  visual?: JSX.Element | string
  action?: JSX.Element | string
  showRecaptchaBranding?: boolean
}

const InfoPageLayout: React.FC<Props> = (props: Props) => {
  const { title, subtitle, visual, action, showRecaptchaBranding }: Props =
    props
  const { classes } = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      justifyContent="center"
      className={classes.container}
    >
      <div className={classes.visual}>{visual}</div>
      <Text
        size={TextSize.lxl}
        weight={TextWeight.SemiBold}
        className={classes.title}
        align={TextAlign.Center}
      >
        {title}
      </Text>
      <Text highlight={TextHighlight.Background} className={classes.subtitle}>
        {subtitle}
      </Text>
      {action}
      {showRecaptchaBranding && (
        <Text
          highlight={TextHighlight.Background}
          size={TextSize.s}
          className={classes.footnote}
        >
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
          <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
          apply.
        </Text>
      )}
    </Grid>
  )
}

export default InfoPageLayout
