import { datadogLogs } from '@datadog/browser-logs'
import { RenderedQuestionnaire } from 'model/questionnaire'
import { useEffect, useState } from 'react'
import { RESPONDENT_QUESTIONNAIRE_API_PATH } from 'utils/constants'
import { apiUrlEndpoint } from 'utils/helper'
import { axiosWithRetry } from './usePostCallback'
import {
  QuestionnaireParams,
  useOptionalQuestionnaireParams
} from './useQuestionnaireParams'
import { useCintQueryParams } from './useCintQueryParams'

interface Data {
  renderedQuestionnaire: RenderedQuestionnaire | undefined
  loading: boolean
  error: boolean
}

const useRenderedQuestionnaire: () => Data = () => {
  const questionnaireParams = useOptionalQuestionnaireParams()
  const cintQueryParams = useCintQueryParams()
  const [data, setData] = useState<Data>({
    renderedQuestionnaire: undefined,
    loading: true,
    error: false
  })

  useEffect(() => {
    const fetchRenderedQuestionnaire = async (
      mandatoryQueryParams: QuestionnaireParams
    ) => {
      const { surveyId, respondentId, panelSupplier, preview } =
        mandatoryQueryParams

      datadogLogs.logger.info('Fetching rendered questionnaire')

      try {
        const { data }: { data: RenderedQuestionnaire } = await axiosWithRetry({
          method: 'get',
          url: `${apiUrlEndpoint}${RESPONDENT_QUESTIONNAIRE_API_PATH}?surveyId=${surveyId}&panelSupplierCode=${panelSupplier}&respondentId=${respondentId}&preview=${preview}`,
          timeout: 30000
        })
        datadogLogs.logger.info('Received questionnaire', {
          prequalified: false
        })
        setData({
          renderedQuestionnaire: data,
          loading: false,
          error: false
        })
      } catch (error) {
        datadogLogs.logger.error(
          'Network Failure: Could not fetch rendered questionnaire',
          {
            error,
            mandatoryQueryParams
          }
        )
        setData({
          renderedQuestionnaire: undefined,
          loading: false,
          error: true
        })
      }
    }

    if (questionnaireParams) {
      fetchRenderedQuestionnaire(questionnaireParams)
    }
  }, [cintQueryParams, questionnaireParams])

  return data
}

export default useRenderedQuestionnaire
