import { makeStyles, Theme } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8, 12)
      },
      padding: theme.spacing(4)
    },
    action: {
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '80%'
      },
      paddingTop: theme.spacing(1.5)
    },
    title: {
      marginBottom: theme.spacing(1)
    },
    terms: {
      width: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    }
  }
})

export default useStyles
