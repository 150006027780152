import React from 'react'
import { FormattedMessage } from 'react-intl'
import { QuestionItem, SettingCodes } from '../../model/questionnaire'
import { getMultipleChoiceInstructions, getSetting } from '../../utils/question'

export const MultipleChoiceLocInstructions = ({
  responseOptions,
  settingValues
}: {
  responseOptions: QuestionItem['responseOptions']
  settingValues: QuestionItem['settingValues']
}) => {
  const limit = getSetting(settingValues, SettingCodes.ChoiceLimit)
  const settingMin = getSetting(
    settingValues,
    SettingCodes.ChoiceIntervalMin
  ) as number | string | undefined
  const settingMax = getSetting(
    settingValues,
    SettingCodes.ChoiceIntervalMax
  ) as number | string | undefined
  // We need to bound the min/max options by the number actually available to select (i.e, if some have been filtered out)
  const filteredRoCount = responseOptions?.length ?? 0
  const boundedMax =
    typeof settingMax !== 'undefined'
      ? Math.min(Number(settingMax), filteredRoCount)
      : undefined
  const boundedMin =
    typeof settingMax !== 'undefined'
      ? Math.min(Number(settingMin), filteredRoCount)
      : undefined

  return (
    <FormattedMessage
      id={getMultipleChoiceInstructions(
        limit,
        boundedMin,
        boundedMax,
        filteredRoCount
      )}
      defaultMessage="Select up to {count} answers"
      values={{
        count: <b>{limit ?? boundedMax}</b>,
        min: <b>{boundedMin}</b>
      }}
    />
  )
}
