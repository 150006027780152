import { makeStyles } from '@focaldata/cin-ui-components'

const useStyles = makeStyles()(() => {
  return {
    iframe: {
      display: 'block',
      border: 'none',
      '&.fdchatLoading': {
        display: 'none'
      }
    },
    spinnerWrapper: {
      display: 'none',
      '&.fdchatLoading': {
        display: 'flex'
      }
    }
  }
})

export default useStyles
