export const getRespondentFreeChoiceKey = (
  respondentId: string,
  surveyId: string
) => `${respondentId}.${surveyId}.respondentFreeTextChoices`

const clear = (respondentId: string, surveyId: string) => {
  window.localStorage.removeItem(
    getRespondentFreeChoiceKey(respondentId, surveyId)
  )
}

const getAll = (
  respondentId: string,
  surveyId: string
): Record<string, string> => {
  return JSON.parse(
    window.localStorage.getItem(
      getRespondentFreeChoiceKey(respondentId, surveyId)
    ) || '{}'
  )
}

const get = (
  respondentId: string,
  surveyId: string,
  questionId: string
): string | undefined => {
  return getAll(respondentId, surveyId)[questionId]
}

const add = (
  respondentId: string,
  surveyId: string,
  questionId: string,
  freeTextResponse: string
) => {
  const freeTextResponsesById = getAll(respondentId, surveyId)
  freeTextResponsesById[questionId] = freeTextResponse
  window.localStorage.setItem(
    getRespondentFreeChoiceKey(respondentId, surveyId),
    JSON.stringify(freeTextResponsesById)
  )
}

const remove = (respondentId: string, surveyId: string, questionId: string) => {
  const persistedFreeTextChoices = getAll(respondentId, surveyId)
  delete persistedFreeTextChoices[questionId]
  const isEmptyFreeTextStorage =
    Object.keys(persistedFreeTextChoices).length === 0

  if (isEmptyFreeTextStorage) {
    clear(respondentId, surveyId)
    return
  }

  window.localStorage.setItem(
    getRespondentFreeChoiceKey(respondentId, surveyId),
    JSON.stringify(persistedFreeTextChoices)
  )
}

export const persistedFreeTextChoicesByQuestionId = {
  clear,
  get,
  add,
  remove
}
