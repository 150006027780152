import { RESPONDENT_API_BASE_PATH } from './constants'

export const isURL = (url: string) => url.includes('http')

export const getApiUrlEndpoint = () => {
  const { REACT_APP_URL_RESPONDENT_API, REACT_APP_DOMAIN } = process.env

  const reactAppUrlRespondentApi =
    REACT_APP_URL_RESPONDENT_API || RESPONDENT_API_BASE_PATH

  return `${
    isURL(reactAppUrlRespondentApi) ? '' : REACT_APP_DOMAIN
  }${reactAppUrlRespondentApi}`
}

export const apiUrlEndpoint = getApiUrlEndpoint()
