import { useCallback, useEffect, useRef, useState } from 'react'

export const useCountdown = (seconds: number) => {
  const [countdown, setCountdown] = useState<number>(seconds)

  const intervalRef = useRef<number>()
  const countdownRef = useRef<number>(seconds)

  useEffect(() => {
    return () => window.clearInterval(intervalRef.current)
  }, [])

  const startCountdown = useCallback(() => {
    countdownRef.current = seconds
    setCountdown(countdownRef.current)
    window.clearInterval(intervalRef.current)

    intervalRef.current = window.setInterval(() => {
      if (countdownRef.current <= 0) {
        window.clearInterval(intervalRef.current)

        return
      }
      countdownRef.current -= 1
      setCountdown(countdownRef.current)
    }, 1000)
  }, [seconds])

  return { countdown, startCountdown }
}
