import {
  SelectedRankedResponse,
  SelectedResponse,
  SelectedSliderResponse
} from '@focaldata/cin-ui-components'
import { ResponseChoiceValue } from 'model/common'
import { EntryResponseOption } from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import { getNowTimeString } from './datetime'

const getSharedResponseOptionChoiceData = (
  responseOption: EntryResponseOption | undefined,
  selectedResponse:
    | SelectedResponse
    | SelectedSliderResponse
    | SelectedRankedResponse
    | undefined
) => {
  return {
    responseOptionId: responseOption?.option.responseOptionId,
    position: responseOption?.position,
    renderedPosition: responseOption?.renderedPosition,
    responseDatetime:
      selectedResponse && selectedResponse.timestamp
        ? selectedResponse.timestamp
        : getNowTimeString()
  }
}

const getSelectedResponseValue: (
  selectedResponse: SelectedResponse | SelectedSliderResponse,
  isTextEntry: boolean | undefined
) => string = (selectedResponse, isTextEntry) => {
  if (isTextEntry) {
    const sr = selectedResponse as SelectedResponse
    return sr.inputText?.text || ''
  }
  return ResponseChoiceValue.Selected
}

export const createResponseOptionChoice: (
  responseOption: EntryResponseOption | undefined,
  selectedResponse: SelectedResponse | SelectedSliderResponse | undefined
) => ResponseOptionChoice = (responseOption, selectedResponse) => {
  const sharedResponseOptionChoiceData = getSharedResponseOptionChoiceData(
    responseOption,
    selectedResponse
  )

  return {
    ...sharedResponseOptionChoiceData,
    value: selectedResponse
      ? getSelectedResponseValue(
          selectedResponse,
          responseOption?.option.isTextEntry
        )
      : ResponseChoiceValue.NotSelected
  }
}

export const createResponseOptionChoiceRanked: (
  responseOption: EntryResponseOption | undefined,
  selectedResponse: SelectedRankedResponse | undefined,
  positionOrder: number | undefined // First, second, third...
) => ResponseOptionChoice = (
  responseOption,
  selectedResponse,
  positionOrder
) => {
  const sharedResponseOptionChoiceData = getSharedResponseOptionChoiceData(
    responseOption,
    selectedResponse
  )

  return {
    ...sharedResponseOptionChoiceData,
    value: selectedResponse
      ? positionOrder?.toString() || '-1' // I decided to use -1 if responseOption is undefined, so that the data science team can identify faster where and why it fails
      : ResponseChoiceValue.NotSelected
  }
}
