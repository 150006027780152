/* eslint-disable no-param-reassign */
import { useContext } from 'react'
import { RawDraftContentState } from '@focaldata/cin-ui-components'

import { EntryType } from 'model/common'
import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import {
  MatrixItem,
  QuestionItem,
  QuestionKind,
  QuestionnaireEntry,
  QuestionTypes,
  TextCardItem
} from 'model/questionnaire'
import getPersistedRespondentChoices from 'utils/getPersistedRespondentChoices'
import { persistedFreeTextChoicesByQuestionId } from 'utils/persistedFreeTextChoicesByQuestionId'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { useQuestionnaireParams } from './useQuestionnaireParams'

const markerStoredRegex = /\{([AQ])-(\d+)\}/g

const oneOfInRange = (nums: number[], [min, max]: [number, number]) => {
  return nums.some((num) => num >= min && num <= max)
}

const findSourceEntry = (
  sourceEntryNumber: number,
  sourceQuestionKind: QuestionKind,
  allEntries: QuestionnaireEntry[]
): QuestionnaireEntry | undefined => {
  return allEntries.find(
    (source) =>
      source.entryNumber === sourceEntryNumber &&
      source.questionKind === sourceQuestionKind
  )
}

const findResponseChoiceText = (
  respondentId: string,
  surveyId: string,
  sourceEntry: QuestionnaireEntry
) => {
  const { renderedContextPosition } = sourceEntry
  const { question, responseOptions, questionTypeCode } =
    sourceEntry.entryItem as QuestionItem
  const sourceEntryQuestionId = question.questionId

  const isFreeTextSource = questionTypeCode === QuestionTypes.FreeText
  const responseChoiceIds =
    getPersistedRespondentChoices(respondentId, surveyId).find(
      (respondentChoice: PersistentRespondentChoice) =>
        respondentChoice.questionId === sourceEntryQuestionId
    )?.responseChoiceIds ?? []
  // source entry can be only single-select
  const responseChoiceId = responseChoiceIds[0]
  const chosenResponseOption = responseOptions?.find(
    (ro) => ro.option.responseOptionId === responseChoiceId
  )
  const freeTextResponse = persistedFreeTextChoicesByQuestionId.get(
    respondentId,
    surveyId,
    sourceEntryQuestionId
  )
  const shouldShowResponceValue =
    (isFreeTextSource && freeTextResponse) ||
    (chosenResponseOption && responseChoiceIds.length === 1)

  const responseValue = isFreeTextSource
    ? freeTextResponse
    : chosenResponseOption?.option.value
  const prefix =
    sourceEntry.questionKind === QuestionKind.AudienceKind ? 'A' : 'Q'
  // take response option value if it was found; not found means that there was no choice by respondent yet (source goes after target or source was skipped) or source is multiselect
  // we show {Q<position>} or {A<position>} instead of selected response option text in this case
  return shouldShowResponceValue
    ? responseValue ?? ''
    : `{${prefix}${renderedContextPosition + 1}}`
}

const getTitleMatches = (
  title: string,
  regex?: RegExp
): RegExpMatchArray | null => {
  return title.match(regex ?? markerStoredRegex)
}

interface SourceQuestionData {
  entryNumber: number
  questionKind: QuestionKind
}
const getQuestionDataFromMarker = (marker: string): SourceQuestionData => {
  const prefix = marker[1]
  const entryNumber = Number(marker.slice(3, -1))
  const questionKind =
    prefix === 'A' ? QuestionKind.AudienceKind : QuestionKind.QuestionnaireKind
  return {
    entryNumber,
    questionKind
  }
}

const getTitleSubstitutions = (
  respondentId: string,
  surveyId: string,
  allEntries: QuestionnaireEntry[],
  rawTitle: string
): Record<string, string> => {
  const titleSubstitutions: Record<string, string> = {}

  getTitleMatches(rawTitle)?.forEach((marker) => {
    const { entryNumber, questionKind } = getQuestionDataFromMarker(marker)
    const sourceEntry = findSourceEntry(entryNumber, questionKind, allEntries)

    // empty curly braces will substitute marker in case source entry wasn't find (in case source entry was deleted, hidden by display logic, or exists on another fork branch)
    let responseChoiceText = '{}'

    if (sourceEntry) {
      responseChoiceText = findResponseChoiceText(
        respondentId,
        surveyId,
        sourceEntry
      )
    }

    titleSubstitutions[marker] = responseChoiceText
  })

  return titleSubstitutions
}

const findStartPatternIndexes = (str: string, pattern: string): number[] => {
  const regex = new RegExp(pattern, 'g')
  let match = regex.exec(str)
  const res = []

  while (match != null) {
    res.push(match.index)
    match = regex.exec(str)
  }

  return res
}

const getViewTitle = (
  respondentId: string,
  surveyId: string,
  allEntries: QuestionnaireEntry[],
  rawTitle: string
): string => {
  let viewTitle = rawTitle
  const titleSubstitutions = getTitleSubstitutions(
    respondentId,
    surveyId,
    allEntries,
    rawTitle
  )

  getTitleMatches(rawTitle)?.forEach((marker) => {
    viewTitle = viewTitle.replace(marker, titleSubstitutions[marker])
  })

  return viewTitle
}

const getViewTitleStyled = (
  respondentId: string,
  surveyId: string,
  allEntries: QuestionnaireEntry[],
  title: string,
  titleStyling: string
): string => {
  const titleMarkerMatches =
    getTitleMatches(titleStyling, markerStoredRegex) || []

  if (titleMarkerMatches.length === 0) {
    return titleStyling
  }

  const titleSubstitutions = getTitleSubstitutions(
    respondentId,
    surveyId,
    allEntries,
    title
  )
  const rawStyledContent: RawDraftContentState = JSON.parse(titleStyling)

  titleMarkerMatches?.forEach((marker) => {
    const responseText = titleSubstitutions[marker]

    if (!responseText) {
      return
    }

    // each block represents one line of styled text
    rawStyledContent.blocks.forEach((block) => {
      const startIndexes = findStartPatternIndexes(block.text, marker)

      const sortedRanges = block.inlineStyleRanges.sort(
        (a, b) => a.offset - b.offset
      )
      // keep initial block object after sorting but before any other mutations occur
      const frozenBlock = JSON.parse(JSON.stringify(block))
      const responseMarkerLengthDiff = responseText.length - marker.length + 1
      block.text = block.text.replaceAll(marker, responseText)

      // for each style adjust style values so that styles are applied to proper parts of the text
      // example: '{Q1} bold' - offset before bold text is 5
      // in case we substitute value to {Q1} and have 'response bold' as a result string, we should update offset so that it is 9 not 5 (4 chars difference between '{Q1}' and 'response' strings)
      sortedRanges.forEach((styleRange, i) => {
        const markersBefore =
          frozenBlock.text
            .slice(0, frozenBlock.inlineStyleRanges[i].offset + 1)
            .match(markerStoredRegex) || []

        let shift = 0

        markersBefore.forEach((marker: string) => {
          const diff = titleSubstitutions[marker].length - marker.length + 1
          shift += diff
        })

        const includesPipingMarker = oneOfInRange(startIndexes, [
          styleRange.offset,
          styleRange.offset + styleRange.length - 1
        ])

        styleRange.offset += shift
        if (includesPipingMarker) {
          styleRange.length += responseMarkerLengthDiff

          shift += responseMarkerLengthDiff
        }
      })
    })
  })

  return JSON.stringify(rawStyledContent)
}

const getEntryWithViewTitle = (
  respondentId: string,
  surveyId: string,
  currentEntry: QuestionnaireEntry,
  allEntries: QuestionnaireEntry[]
): QuestionnaireEntry => {
  switch (currentEntry.entryType) {
    case EntryType.MatrixEntryType: {
      const entryItem = currentEntry.entryItem as MatrixItem
      const { matrixTitle } = entryItem
      const { title, titleStyling } = matrixTitle
      return {
        ...currentEntry,
        entryItem: {
          ...entryItem,
          matrixTitle: {
            ...matrixTitle,
            title: getViewTitle(respondentId, surveyId, allEntries, title),
            titleStyling:
              titleStyling &&
              getViewTitleStyled(
                respondentId,
                surveyId,
                allEntries,
                title,
                titleStyling
              )
          }
        }
      }
    }
    case EntryType.QuestionEntryType: {
      const entryItem = currentEntry.entryItem as QuestionItem
      const { question } = entryItem
      const { text, textStyling } = question
      return {
        ...currentEntry,
        entryItem: {
          ...entryItem,
          question: {
            ...question,
            text: getViewTitle(respondentId, surveyId, allEntries, text),
            titleStyling:
              textStyling &&
              getViewTitleStyled(
                respondentId,
                surveyId,
                allEntries,
                text,
                textStyling
              )
          }
        }
      }
    }
    case EntryType.TextCardEntryType: {
      const entryItem = currentEntry.entryItem as TextCardItem
      const { textCard } = entryItem
      const { title, titleStyling } = textCard
      return {
        ...currentEntry,
        entryItem: {
          ...entryItem,
          textCard: {
            ...textCard,
            title: getViewTitle(respondentId, surveyId, allEntries, title),
            titleStyling:
              titleStyling &&
              getViewTitleStyled(
                respondentId,
                surveyId,
                allEntries,
                title,
                titleStyling
              )
          }
        }
      }
    }
    default:
      break
  }
  return currentEntry
}

const useQuestionPiping = (): QuestionnaireEntry | undefined => {
  const {
    respondentProgress: [respondentProgress],
    renderedQuestionnaire: [renderedQuestionnaire]
  } = useContext<AppState>(AppStateContext)
  const mandatoryParams = useQuestionnaireParams()

  if (!renderedQuestionnaire || !respondentProgress) {
    return undefined
  }

  const currentPosition = respondentProgress.currentEntryPosition
  const { entries } = renderedQuestionnaire.questionnaire
  const currentEntry: QuestionnaireEntry = entries[currentPosition]
  return getEntryWithViewTitle(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    currentEntry,
    entries
  )
}

export default useQuestionPiping
