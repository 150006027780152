import { Button } from '@focaldata/cin-ui-components'
import xIcon from 'assets/cancel.svg'
import InfoPageLayout from 'layouts/InfoPageLayout/InfoPageLayout'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const ErrorOverlay: React.FC = () => {
  return (
    <InfoPageLayout
      visual={<img src={xIcon} alt="error icon" />}
      title={
        <FormattedMessage
          id="errorPage_Oops"
          defaultMessage="Oops, there seems to be an error getting your survey"
        />
      }
      subtitle={
        <FormattedMessage
          id="errorPage_pleaseVisit"
          defaultMessage="Please visit our website for additional information"
        />
      }
      action={
        <Button
          onClick={() => {
            window.open('https://focaldata.com')
          }}
        >
          <FormattedMessage
            id="errorPage_visitFocaldata"
            defaultMessage="Visit focaldata.com"
          />
        </Button>
      }
    />
  )
}

export default ErrorOverlay
