import { MatrixItem } from 'model/questionnaire'
import getPersistedRespondentChoices from 'utils/getPersistedRespondentChoices'

export const isMatrixQualified = (
  respondentId: string,
  surveyId: string,
  entryItem: MatrixItem
) => {
  const persistedResponseChoices = getPersistedRespondentChoices(
    respondentId,
    surveyId
  )
  const { matrixQualification } = entryItem

  if (!matrixQualification) {
    return true
  }

  const { qualifyingOptions, matrixRowQualifyingCondition } =
    matrixQualification
  const { numberOfQualifyingRows, operator } = matrixRowQualifyingCondition

  const matrixRowsResponded = persistedResponseChoices.filter(
    ({ matrixTitleId }) => {
      return matrixTitleId === entryItem.matrixTitle.matrixTitleId
    }
  )

  const qualifyingMatrixRowsResponded = matrixRowsResponded.filter(
    ({ responseChoiceIds }) => {
      return responseChoiceIds.some((id) => qualifyingOptions.includes(id))
    }
  )

  switch (operator) {
    case 'GreaterThanOrEquals':
      return qualifyingMatrixRowsResponded.length >= numberOfQualifyingRows
    // NOTE: this operator is not used now, the logic how it should be used may be changed
    case 'LessThanOrEquals':
      return qualifyingMatrixRowsResponded.length <= numberOfQualifyingRows
    default:
      return true
  }
}
