import { EntryType } from 'model/common'
import {
  RenderedQuestionnaire,
  QuestionItem,
  MatrixItem,
  QuestionnaireEntry,
  TextCardItem
} from 'model/questionnaire'
import { getRespondentChoiceKey } from './constants'
import getPersistedRespondentChoices from './getPersistedRespondentChoices'

export const getEntryId = (
  questionnaireEntry: QuestionnaireEntry | undefined
) => {
  if (
    questionnaireEntry &&
    questionnaireEntry.entryType === EntryType.QuestionEntryType
  ) {
    return (questionnaireEntry.entryItem as QuestionItem).question.questionId
  }
  if (
    questionnaireEntry &&
    questionnaireEntry.entryType === EntryType.MatrixEntryType
  ) {
    return (questionnaireEntry.entryItem as MatrixItem).matrixTitle
      .matrixTitleId
  }
  if (
    questionnaireEntry &&
    questionnaireEntry.entryType === EntryType.TextCardEntryType
  ) {
    return (questionnaireEntry.entryItem as TextCardItem).textCard.textCardId
  }
  return ''
}

export const clearResponseChoices = (
  respondentId: string,
  surveyId: string
) => {
  window.localStorage.removeItem(getRespondentChoiceKey(respondentId, surveyId))
}

const removeRespondentChoiceFromLocalstorage = (
  respondentId: string,
  surveyId: string,
  data: RenderedQuestionnaire | undefined,
  entryPosition?: number
) => {
  if (entryPosition !== undefined) {
    const persistenRespondentChoices = getPersistedRespondentChoices(
      respondentId,
      surveyId
    )

    const entry = data?.questionnaire?.entries.find(
      (entry) => entry.renderedEntryPosition === entryPosition
    )

    const entryId = getEntryId(entry)

    const newPersistenRespondentChoices = persistenRespondentChoices.filter(
      (respondentChoice) => {
        return (
          respondentChoice.questionId !== entryId &&
          respondentChoice.matrixTitleId !== entryId
        )
      }
    )
    window.localStorage.setItem(
      getRespondentChoiceKey(respondentId, surveyId),
      JSON.stringify(newPersistenRespondentChoices)
    )
  }
}

export default removeRespondentChoiceFromLocalstorage
