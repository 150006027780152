import React, { useContext } from 'react'
import { SelectedResponse, SingleChoice } from '@focaldata/cin-ui-components'
import {
  CfmContext,
  CfmData
} from 'containers/QuestionFlowManager/QuestionFlowManager'
import useEntryProgress from 'hooks/useEntryProgress'
import useEntryRenderingDateTime from 'hooks/useEntryRenderingDateTime'
import persistRespondentChoice from 'utils/persistRespondentChoices'
import useSendResponseChoice from 'hooks/useSendResponseChoice'
import { QuestionItem, QuestionnaireEntry } from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'

import { FormattedMessage, useIntl } from 'react-intl'
import { createResponseOptionChoice } from 'utils/responseChoice'
import displayEntityBasedOnLogic from 'utils/displayEntityBasedOnLogic'
import { setHiddenByDisplayLogic } from 'utils/hiddenByDisplayLogic'
import {
  entryHasNoNonDefaultResponseOptions,
  getVisibleResponseOptions
} from 'utils/responseOptionsMaskingLogic'
import { useQuestionTimer } from 'hooks/useQuestionTimer'
import { useQuestionnaireParams } from '../../hooks/useQuestionnaireParams'
import { getResponseChoiceIds } from './Entry.utils'

interface Props {
  entry: QuestionnaireEntry
}

// TODO: may want to merge this and multiple choice?
const SingleChoiceEntry: React.FC<Props> = (props: Props) => {
  const { entry }: Props = props
  const entryItem = entry.entryItem as QuestionItem
  const renderingDateTime = useEntryRenderingDateTime(entry)
  const progress = useEntryProgress(entry)
  const { getNextEntryPosition, preview } = useContext<CfmData>(CfmContext)
  const sendResponseOptionChoices = useSendResponseChoice()
  const { formatMessage } = useIntl()
  const timer = useQuestionTimer(entryItem)
  const mandatoryParams = useQuestionnaireParams()

  const loc = {
    next: <FormattedMessage id="question_next" defaultMessage="Next" />,
    instructions: (
      <FormattedMessage
        id="question_singleChoiceInstructions"
        defaultMessage="Select one option"
      />
    ),
    typeYourAnswerHere: formatMessage({
      id: 'question_freeTextPlaceholder',
      defaultMessage: 'Type your answer here...'
    })
  }

  const getResponseOptionChoices: (
    selectedResponse: SelectedResponse
  ) => ResponseOptionChoice[] = (selectedResponse) => {
    return (
      entryItem.responseOptions?.map((responseOption) => {
        const selected =
          responseOption.position === selectedResponse.selectedPosition
        return createResponseOptionChoice(
          responseOption,
          selected ? selectedResponse : undefined
        )
      }) || []
    )
  }

  const handleNext: (selectedResponse: SelectedResponse) => void = (
    selectedResponse
  ) => {
    const respondentChoice = {
      questionId: entryItem.question.questionId,
      responseChoiceIds: getResponseChoiceIds(entryItem, [selectedResponse])
    }
    persistRespondentChoice(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      respondentChoice
    )

    if (!preview) {
      sendResponseOptionChoices({
        questionId: entryItem.question.questionId,
        entry,
        entryType: entry.entryType,
        renderingDateTime,
        responseChoices: getResponseOptionChoices(selectedResponse)
      })
    }

    getNextEntryPosition([selectedResponse.selectedPosition])
  }

  const shouldDisplayEntityBasedOnLogic = displayEntityBasedOnLogic(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    entryItem.questionLogic,
    entry.entryType
  )

  if (!shouldDisplayEntityBasedOnLogic) {
    setHiddenByDisplayLogic(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      entryItem.question.questionId
    )
    getNextEntryPosition()
  }

  const entryItemWithFilteredResponseOptions = getVisibleResponseOptions(
    mandatoryParams.respondentId,
    mandatoryParams.surveyId,
    entryItem
  )

  if (
    entryHasNoNonDefaultResponseOptions(
      entryItemWithFilteredResponseOptions.responseOptions
    )
  ) {
    setHiddenByDisplayLogic(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId,
      entryItem.question.questionId
    )
    getNextEntryPosition()
  }

  return (
    <SingleChoice
      item={entryItemWithFilteredResponseOptions}
      timer={timer}
      loc={loc}
      progress={progress}
      preview={preview}
      next={{ disabled: false, loading: false, onNext: handleNext }}
      dataCy="response-option"
    />
  )
}

export default SingleChoiceEntry
