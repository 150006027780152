export interface AudienceReponseOption {
  surveyId: string
  questionId: string
  responseOptionId: string
}

export interface MatrixResponseOption {
  matrixTitleId: string
  questionId: string
  responseOptionId: string
}

export interface MatrixResponseOptionCount {
  matrixTitleId: string
  questionId: string
  count: number
}

export interface MatrixRowCount {
  matrixTitleId: string
  responseOptionId: string
  count: number
}

export interface QuestionResponseOption {
  questionId: string
  responseOptionId: string
}

export interface QuestionResponseOptionCount {
  questionId: string
  count: number
}

export interface QuestionResponseOptionRank {
  QuestionResponseOptionRank: {
    questionId: string
    responseOptionId: string
    ranked: number
  }
}

export type LogicProposition =
  | AudienceReponseOption
  | MatrixResponseOption
  | MatrixResponseOptionCount
  | MatrixRowCount
  | QuestionResponseOption
  | QuestionResponseOptionCount

export enum LogicPropositionType {
  AudienceReponseOptionType = 'AudienceReponseOptionType',
  MatrixResponseOptionType = 'MatrixResponseOptionType',
  MatrixResponseOptionCountType = 'MatrixResponseOptionCountType',
  MatrixRowCountType = 'MatrixRowCountType',
  QuestionResponseOptionType = 'QuestionResponseOptionType',
  QuestionResponseOptionCountType = 'QuestionResponseOptionCount'
}

export interface LogicClauseProposition {
  clause: number
  propositionType: LogicPropositionType
  proposition: LogicProposition
  negated: Boolean
}

export enum LogicClauseAsNumber {
  AND = 1,
  OR = 0
}
