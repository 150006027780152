import axios, { AxiosResponse, AxiosError } from 'axios'
import axiosRetry from 'axios-retry'
import { datadogLogs } from '@datadog/browser-logs'
import { useCallback } from 'react'
import useCollectDataParameter from './useCollectDataParameter'

export type Callback<T> = (
  payload: T,
  url: string
) => Promise<AxiosResponse<any, any> | void>

export const axiosWithRetry = axios.create()

axiosRetry(axiosWithRetry, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  onRetry(retryCount, error, requestConfig) {
    datadogLogs.logger.warn('Network request failed, retrying', {
      error,
      requestConfig,
      retryCount
    })
  },
  retryCondition(error: AxiosError) {
    const resStatus = error.response?.status ?? 500
    return resStatus >= 400
  }
})

const createUsePostCallback = (withRetry: boolean) => {
  const httpClient = withRetry ? axiosWithRetry : axios

  const usePostCallback: <T>() => Callback<T> = <T>() => {
    const collectData = useCollectDataParameter()
    const postRequest = useCallback<Callback<T>>((payload, url) => {
      return httpClient.post(url, payload)
    }, [])
    const postRequestStub = useCallback(() => Promise.resolve(), [])

    return collectData ? postRequest : postRequestStub
  }

  return usePostCallback
}

const usePostCallback = createUsePostCallback(false)

export const usePostCallbackWithRetry = createUsePostCallback(true)

export default usePostCallback
