import {
  MatrixItem,
  QuestionItem,
  QuestionnaireEntry,
  QuestionTypes,
  SettingCodes,
  SettingValues
} from 'model/questionnaire'
import { EntryType } from 'model/common'
import { getSetting } from '../../../utils/question'
import { isMatrixQualified } from '.'

export const shouldScreenOut = (
  respondentId: string,
  surveyId: string,
  currentEntry: QuestionnaireEntry,
  selectedResponseOptionPositions: number[]
): boolean => {
  if (currentEntry.entryType === EntryType.MatrixEntryType) {
    return !isMatrixQualified(
      respondentId,
      surveyId,
      currentEntry.entryItem as MatrixItem
    )
  }

  const currentQuestion = currentEntry.entryItem as QuestionItem
  if (
    currentQuestion.questionTypeCode !== QuestionTypes.StandardAudience &&
    currentQuestion.questionTypeCode !== QuestionTypes.CustomAudience
  ) {
    return false
  }

  const selectedPositionsSet = new Set(selectedResponseOptionPositions)
  const selectedQualifying =
    currentQuestion.responseOptions?.filter(
      (responseOption) =>
        selectedPositionsSet.has(responseOption.position) &&
        responseOption.option.isQualifying
    ) ?? []

  const isMultipleChoice = currentQuestion.settingValues?.some(
    (settingValue) => settingValue.value === SettingValues.MultipleChoice
  )

  const settingLimit = getSetting(
    currentQuestion.settingValues,
    SettingCodes.ScreeningQuestionResponseLimit
  )

  if (
    isMultipleChoice &&
    typeof settingLimit !== 'undefined' &&
    selectedQualifying.length < Number(settingLimit)
  ) {
    return true
  }

  return selectedQualifying.length === 0
}
