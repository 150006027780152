import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useQueryParams from './useQueryParams'

export interface QuestionnaireParams {
  respondentId: string
  surveyId: string
  panelSupplier: string
  preview: boolean
}

const generateRespondentId = (queryParams: URLSearchParams) => {
  const rid = uuidv4()
  queryParams.set('rid', rid)
  window.location.search = queryParams.toString()

  return rid
}

export const isPreviewMode = (queryParams: URLSearchParams) => {
  return queryParams.get('preview') === 'true'
}

export const getQuestionnaireQueryParams = (
  queryParams: URLSearchParams
): QuestionnaireParams | null => {
  const respondentId: string | null =
    queryParams.get('rid') ??
    (queryParams.get('type') === 'fdchat'
      ? generateRespondentId(queryParams)
      : null)
  const surveyId: string | null = queryParams.get('sid')
  const panelSupplier: string | null = queryParams.get('ps')

  if (!respondentId || !surveyId || !panelSupplier) {
    return null
  }

  return {
    respondentId,
    surveyId,
    panelSupplier,
    preview: isPreviewMode(queryParams)
  }
}

export const useOptionalQuestionnaireParams =
  (): QuestionnaireParams | null => {
    const queryParams = useQueryParams()

    const [state] = useState(() => getQuestionnaireQueryParams(queryParams))

    return state
  }

export const useQuestionnaireParams = (): QuestionnaireParams => {
  const queryParams = useQueryParams()

  const [state] = useState(() => {
    const params = getQuestionnaireQueryParams(queryParams)

    if (!params) {
      throw new Error('Query params missing, cannot render survey')
    }

    return params
  })

  return state
}
