import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { AppState, AppStateContext } from 'containers/State/AppState'
import { useQuestionnaireParams } from 'hooks/useQuestionnaireParams'
import { noContextMethod } from 'utils/context'
import {
  clearQuestionsHiddenByDisplayLogic,
  isQuestionHiddenByDisplayLogic,
  removeHiddenByDisplayLogic
} from 'utils/hiddenByDisplayLogic'
import {
  clearQuestionsHiddenByLooping,
  clearQuestionsHiddenByLoopingByEntry,
  isEntryHiddenByLooping
} from 'utils/hiddenByLooping'
import removeRespondentChoiceFromLocalstorage, {
  clearResponseChoices,
  getEntryId
} from 'utils/removeRespondentChoiceFromLocalstorage'
import { persistedFreeTextChoicesByQuestionId } from 'utils/persistedFreeTextChoicesByQuestionId'
import { useNextEntryPosition } from 'hooks/useNextEntryPosition'
import { getForkDetailsForEntry } from './QuestionFlowManager.utils'
import { useQuestionFlowManager } from './QuestionFlowManager.hooks'

export interface CfmData {
  getNextEntryPosition: (selectedPositions?: number[]) => void
  preview?: {
    isPreview: boolean
    forkDetails?: JSX.Element | string
    onNavigateForward: () => void
    onNavigateBackward: () => void
    onClickRefresh: () => void
  }
}

export const CfmContext = React.createContext<CfmData>({
  getNextEntryPosition: noContextMethod
})

const useWarnBeforeExit = () => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])
}

const QuestionFlowManager = (props: PropsWithChildren<{}>) => {
  const { children }: PropsWithChildren<{}> = props
  const mandatoryParams = useQuestionnaireParams()
  const isPreviewMode = mandatoryParams?.preview
  const {
    respondentProgress: [respondentProgress],
    renderedQuestionnaire: [data]
  } = useContext<AppState>(AppStateContext)
  const {
    setProgress,
    handleRouting,
    handleMatrixLoopingQuotaFull,
    handleQualityTerminate,
    handleScreenOut,
    handleQuotaFull
  } = useQuestionFlowManager()

  const nextEntryPosition = useNextEntryPosition()

  useWarnBeforeExit()

  const handleGoToNext = () => {
    if (respondentProgress) {
      setProgress(nextEntryPosition)
    }
  }

  const getNextEntryPosition = (selectedPositions?: number[]) => {
    if (!selectedPositions) {
      handleGoToNext()
      return
    }

    const wasRouted = handleRouting(selectedPositions)
    if (wasRouted) {
      return
    }

    // careful with the order here. qualityTerminate before screenOut or quotaFull
    const wasQualityTerminated = handleQualityTerminate(selectedPositions)
    if (wasQualityTerminated) {
      return
    }

    const wasScreenedOut = handleScreenOut(selectedPositions)
    if (wasScreenedOut) {
      return
    }

    const matrixLoopingQuotaFull = handleMatrixLoopingQuotaFull()

    if (matrixLoopingQuotaFull) {
      return
    }

    const wasQuotaFull = handleQuotaFull(selectedPositions[0])
    if (wasQuotaFull) {
      return
    }

    handleGoToNext()
  }

  const handleRefresh = () => {
    setProgress(0)
    clearResponseChoices(mandatoryParams.respondentId, mandatoryParams.surveyId)
    persistedFreeTextChoicesByQuestionId.clear(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId
    )
    clearQuestionsHiddenByDisplayLogic(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId
    )
    clearQuestionsHiddenByLooping(
      mandatoryParams.respondentId,
      mandatoryParams.surveyId
    )
  }

  const moveForward = () => {
    if (isPreviewMode && respondentProgress) {
      setProgress(respondentProgress.currentEntryPosition + 1)
    }
  }

  const getPreviousVisibleEntryPosition = (
    currentEntryPosition: number
  ): number => {
    const previousEntry = data?.questionnaire?.entries.find(
      (entry) => entry.renderedEntryPosition === currentEntryPosition - 1
    )

    if (
      previousEntry &&
      isEntryHiddenByLooping(
        mandatoryParams.respondentId,
        mandatoryParams.surveyId,
        previousEntry.entryNumber
      )
    ) {
      return getPreviousVisibleEntryPosition(currentEntryPosition - 1)
    }

    if (
      isQuestionHiddenByDisplayLogic(
        mandatoryParams.respondentId,
        mandatoryParams.surveyId,
        getEntryId(previousEntry)
      )
    ) {
      removeRespondentChoiceFromLocalstorage(
        mandatoryParams.respondentId,
        mandatoryParams.surveyId,
        data,
        currentEntryPosition - 1
      )
      removeHiddenByDisplayLogic(
        mandatoryParams.respondentId,
        mandatoryParams.surveyId,
        data,
        currentEntryPosition - 1
      )
      return getPreviousVisibleEntryPosition(currentEntryPosition - 1)
    }

    return previousEntry?.renderedEntryPosition || 0
  }

  const moveBack = () => {
    if (isPreviewMode && respondentProgress) {
      const previousEntryPosition = getPreviousVisibleEntryPosition(
        respondentProgress.currentEntryPosition
      )
      const previousQuestionEntry =
        data?.questionnaire.entries[previousEntryPosition]

      setProgress(previousEntryPosition)
      removeRespondentChoiceFromLocalstorage(
        mandatoryParams.respondentId,
        mandatoryParams.surveyId,
        data,
        previousEntryPosition
      )
      removeHiddenByDisplayLogic(
        mandatoryParams.respondentId,
        mandatoryParams.surveyId,
        data,
        previousEntryPosition
      )

      if (previousQuestionEntry?.looping)
        clearQuestionsHiddenByLoopingByEntry(
          mandatoryParams.respondentId,
          mandatoryParams.surveyId,
          previousQuestionEntry
        )
    }
  }

  const contextValue: CfmData = isPreviewMode
    ? {
        getNextEntryPosition,
        preview: {
          isPreview: true,
          forkDetails: getForkDetailsForEntry(
            data?.questionnaire.entries[
              respondentProgress?.currentEntryPosition || 0
            ]
          ),
          onNavigateForward: moveForward,
          onNavigateBackward: moveBack,
          onClickRefresh: handleRefresh
        }
      }
    : { getNextEntryPosition }

  return (
    <CfmContext.Provider value={contextValue}>{children}</CfmContext.Provider>
  )
}

export default QuestionFlowManager
