import { SelectedResponse } from '@focaldata/cin-ui-components'
import { EntryResponseOption } from 'model/questionnaire'
import { ResponseOptionChoice } from 'model/responseChoice'
import { createResponseOptionChoice } from 'utils/responseChoice'

export const buildResponseOptionChoicesForSelectedResponses = (
  selectedResponses: SelectedResponse[],
  visibleResponseOptions: EntryResponseOption[]
): ResponseOptionChoice[] => {
  const selectedResponseByPosition = new Map(
    selectedResponses.map((response) => [response.selectedPosition, response])
  )

  return (
    visibleResponseOptions?.map((responseOption) => {
      const selectedResponse = selectedResponseByPosition.get(
        responseOption.position
      )
      return createResponseOptionChoice(responseOption, selectedResponse)
    }) || []
  )
}
