import React from 'react'
import { Grid, Text, TextWeight } from '@focaldata/cin-ui-components'
import { getTerms } from './getTerms'

const Terms = () => {
  return (
    <Grid container justifyContent="center">
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Text weight={TextWeight.SemiBold}>
            <br />
            PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS
            SITE AND PARTICIPATING IN OUR SURVEYS
            <br />
            <br />
          </Text>
          <Text>
            Privacy Notice for Market Research and Survey Participants
          </Text>
          <Text>
            Welcome to the focaldata’s privacy notice. This privacy notice
            provides information on how focaldata collects and processes your
            personal data when you access our survey platform. It is important
            that you read this privacy notice before participating in the
            survey.
          </Text>
        </Grid>
        {getTerms.map((term) => (
          <Grid item key={`${term}-${term.id}`}>
            <Text>{term.title}</Text>
            <Text>{term.body}</Text>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Terms
