import { SettingValues } from 'model/questionnaire'

export type Validation = {
  matchPattern: RegExp
  helperText: string
}

export const DEFAULT_LOCALE = 'en-GB'

const postCodeRegexByLocale = new Map<string, RegExp>([
  ['en-GB', /^\s*[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s\d{1}\s*$/],
  ['en-GB_full', /^\s*[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s\d{1}[a-zA-Z]{2}\s*$/],
  ['en-US', /^\s*\d{5}(-\d{4})?\s*$/]
])

const postCodeHelperTextsByLocale = new Map<string, string>([
  [
    'en-GB',
    'Sorry, please enter the postcode with the last two letters missing (e.g. SW1 5, EH 8)'
  ],
  ['en-GB_full', 'Sorry, please enter the postcode (e.g. SW1A 2AA, EH8 8AJ)'],
  ['en-US', 'Sorry, please enter a valid ZIP code']
])

export const postCodeInstructionsByLocale = new Map<string, string>([
  [
    'en-GB',
    'Please enter your postcode without the last two letters, e.g. NQ1 6AH -> NQ1 6, EZ8 8AJ -> EZ8 8, WF2B 4AB -> WF2B 4'
  ],
  ['en-GB_full', 'Please enter your postcode, e.g. NQ1 6AH, EZ8 8AJ, WC2F 4AB'],
  ['en-US', 'Please enter your ZIP code in this format: 99999 or 99999-9999']
])

export const getEmailValidation = (): Validation => {
  return {
    matchPattern: /^\w[^\s@]+@\w[^\s@]+\w$/,
    helperText: 'Sorry, please enter a valid email address'
  }
}

export const getPostcodeValidation = (localeKey: string): Validation => {
  // This is for now only activated for the UK so there's no need to translate these
  // const validationString: string = type.toString()
  // const reg = new RegExp(validationString.replace('postcode_uk__', ''))
  const reg =
    postCodeRegexByLocale.get(localeKey) ||
    /^\s*[a-zA-Z]{1,2}\d[a-zA-Z\d]?\s\d{1}\s*$/
  return {
    matchPattern: reg,
    helperText:
      postCodeHelperTextsByLocale.get(localeKey) ||
      postCodeHelperTextsByLocale.get(DEFAULT_LOCALE) ||
      ''
  }
}

export const getNumberValidation = (): Validation => {
  return {
    matchPattern: /^-?[0-9]\d*(\.\d+)?$/,
    helperText: ''
  }
}

const getMinCharactersPatternMatcher = (
  minCharactersRequired: SettingValues
): Pick<RegExp, 'test'> => {
  return {
    test: (text: string) => text.trim().length >= Number(minCharactersRequired)
  }
}

export const getMinCharactersValidation = (
  minCharactersRequired: SettingValues
): Validation => {
  return {
    matchPattern: getMinCharactersPatternMatcher(
      minCharactersRequired
    ) as RegExp,
    helperText: `At least ${minCharactersRequired} character(s) required to move on to the next question`
  }
}
