export const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'
export const RESPONDENT_API_BASE_PATH = '/v3/respondent'
export const RESPONSE_CHOICE_API_PATH = '/api/v1.0/responseChoice'
export const RESPONDENT_STATUS_API_PATH = '/api/v1.0/respondentStatus'
export const RESPONDENT_INFO_API_PATH = '/api/v1.0/respondentInfo'
export const RESPONDENT_QUESTIONNAIRE_API_PATH = '/api/v1.0/questionnaire'
export const ROUTE_TO_END_SURVEY_NUMBER = 100000
export const SETTING_VALUE_MULTIPLE_CHOICE = 'multiple_choice'
// @todo move this
export const getRespondentChoiceKey = (
  respondentId: string,
  surveyId: string
) => `${respondentId}.${surveyId}.respondentChoices`
