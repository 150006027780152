import { EntrySettingValue, SettingValues } from 'model/questionnaire'

export const getSetting = (
  settingValues: EntrySettingValue[] | undefined,
  code: string
) => {
  if (!settingValues) {
    return undefined
  }

  const setting = settingValues.find(
    (settingValue) => settingValue.code === code
  ) as EntrySettingValue
  if (setting) {
    return setting.value
  }

  return undefined
}

export const isSettingEnabled = (
  settingValues: EntrySettingValue[] | undefined,
  code: EntrySettingValue['code']
): boolean => {
  return getSetting(settingValues, code) === SettingValues.Enabled
}

export const getMultipleChoiceInstructions: (
  limit?: string,
  min?: number,
  max?: number,
  responseOptionsCount?: number
) => string = (limit, min, max, responseOptionsCount) => {
  if (min !== undefined && max) {
    if (min === max) return 'question_multipleChoiceNoFixedInstructions'
    if (min !== 0) return 'question_multipleChoiceRangeInstructions'
  }
  return limit !== undefined ||
    (min === 0 && max && responseOptionsCount && max < responseOptionsCount)
    ? 'question_multipleChoiceInstructions'
    : 'question_multipleChoiceNoLimitInstructions'
}

export const getMultipleChoiceMatrixDesktopInstructions: (
  limit?: string,
  min?: number,
  max?: number,
  responseOptionsCount?: number
) => string = (limit, min, max, responseOptionsCount) => {
  if (min !== undefined && max) {
    if (min === max)
      return 'question_matrixSelectMultipleAnswerNoFixedInstructions'
    if (min !== 0) return 'question_matrixSelectMultipleAnswerRangeInstructions'
  }
  return limit !== undefined ||
    (min === 0 && max && responseOptionsCount && max < responseOptionsCount)
    ? 'question_matrixSelectMultipleAnswersDesktop'
    : 'question_multipleChoiceNoLimitInstructions'
}
