import React, { ReactNode, useContext } from 'react'
import { IntlProvider as Intl } from 'react-intl'
import staticCopy from 'intl/copy'
import { AppStateContext } from 'containers/State/AppState'
import { ThemeProvider } from '@focaldata/cin-ui-components'
import { ResolvedIntlConfig } from 'react-intl/src/types'
import { Questionnaire } from '../model/questionnaire'
import { getDirection } from './IntlUtils'

type Locale = Questionnaire['locale']

const copy = staticCopy as Record<
  string,
  ResolvedIntlConfig['messages'] | undefined
>

const IntlProvider = ({ children }: { children: ReactNode }) => {
  const {
    renderedQuestionnaire: [questionnaire]
  } = useContext(AppStateContext)

  const locale: Locale = questionnaire?.questionnaire.locale ?? {
    language: 'en',
    country: 'GB'
  }

  const direction = getDirection(questionnaire?.questionnaire.locale.language)
  const fullLocale = `${locale.language}-${locale.country}`

  const messages = copy[fullLocale] ?? copy[locale.language]

  if (!messages) {
    throw new Error(`Cannot render survey for ${fullLocale}`)
  }

  return (
    <ThemeProvider direction={direction}>
      <Intl locale={fullLocale} messages={messages}>
        {children}
      </Intl>
    </ThemeProvider>
  )
}

export default IntlProvider
