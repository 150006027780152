import { QuestionnaireEntry } from 'model/questionnaire'
import { useEffect, useState } from 'react'
import { getNowTimeString } from 'utils/datetime'

const useEntryRenderingDateTime: (entry: QuestionnaireEntry) => string = (
  entry
) => {
  const [renderingDateTime, setRenderingDateTime] = useState<string>(
    getNowTimeString()
  )

  useEffect(() => {
    setRenderingDateTime(getNowTimeString())
  }, [entry])

  return renderingDateTime
}

export default useEntryRenderingDateTime
