import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import CompletePage from 'containers/CompletePage/CompletePage'
import InfoPage from 'containers/InfoPage/InfoPage'
import PageFlowManager from 'containers/PageFlowManager/PageFlowManager'
import QuestionFlowManager from 'containers/QuestionFlowManager/QuestionFlowManager'
import QuestionnairePage from 'containers/QuestionnairePage/QuestionnairePage'
import AppStateProvider from 'containers/State/AppState'
import TermsPage from 'containers/TermsPage/TermsPage'
import IntlProvider from 'intl/IntlProvider'
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Route, Routes } from 'react-router-dom'
import { getEnvironment } from 'utils/deployment'
import packageJson from '../package.json'
import { isPreviewMode } from './hooks/useQuestionnaireParams'

let datadogInit = false

const initDatadogLogs = () => {
  if (datadogInit) {
    return
  }

  const datadogVersion =
    process.env.REACT_APP_VERSION ?? `fallback-${packageJson.version}`
  const datadogEnv = getEnvironment()

  datadogLogs.init({
    clientToken: 'pub27e5a7b179a84d279b3fb843dcf44be0',
    site: 'datadoghq.eu',
    service: 'fd-respondent-app',
    forwardErrorsToLogs: true,
    env: datadogEnv,
    version: datadogVersion,
    sessionSampleRate: 100
  })

  const searchParams = new URLSearchParams(window.location.search)
  const respondentParams = Object.fromEntries(searchParams)

  datadogLogs.setUser(respondentParams)

  window.addEventListener('beforeunload', () => {
    datadogLogs.logger.info('Respondent attempted to leave the page', {
      respondentParams
    })
  })

  document.addEventListener('visibilitychange', () => {
    datadogLogs.logger.log('Document visibility changed', {
      visibilityState: document.visibilityState,
      respondentParams
    })
  })

  if (isPreviewMode(searchParams)) {
    datadogRum.init({
      applicationId: 'c637ac3b-f25e-4320-9154-8bde6d28413e',
      clientToken: 'pub0f0d25c23c7c9dc4ea98f3c4c2694797',
      site: 'datadoghq.eu',
      service: packageJson.name,
      env: datadogEnv,
      version: datadogVersion,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow'
    })

    datadogRum.setUser(respondentParams)
    datadogRum.startSessionReplayRecording()
  }

  datadogInit = true
}

const App = () => {
  initDatadogLogs()

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}
      language="en-GB"
      useEnterprise
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
    >
      <AppStateProvider>
        <IntlProvider>
          <PageFlowManager>
            <Routes>
              <Route path="/info" element={<InfoPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route
                path="/questionnaire"
                element={
                  <QuestionFlowManager>
                    <QuestionnairePage />
                  </QuestionFlowManager>
                }
              />
              <Route path="/complete" element={<CompletePage />} />
            </Routes>
          </PageFlowManager>
        </IntlProvider>
      </AppStateProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App
