import { PersistentRespondentChoice } from 'model/persistentRespondentChoice'
import { MaskingKey, MaskingRule, MaskingRuleClause } from 'model/questionnaire'
import { isQuestionHiddenByDisplayLogic } from './hiddenByDisplayLogic'

const maskingKeyFoundInPersistedRespondentChoices =
  (
    respondentId: string,
    surveyId: string,
    persistedRespondentChoices: PersistentRespondentChoice[]
  ) =>
  (maskingKey: MaskingKey): boolean => {
    let choice: PersistentRespondentChoice | undefined

    if (maskingKey.matrixTitleId) {
      choice = persistedRespondentChoices.find(
        (persistedChoice) =>
          persistedChoice.matrixTitleId === maskingKey.matrixTitleId &&
          persistedChoice.questionId === maskingKey.questionId
      )
      if (
        isQuestionHiddenByDisplayLogic(
          respondentId,
          surveyId,
          maskingKey.matrixTitleId
        ) ||
        isQuestionHiddenByDisplayLogic(
          respondentId,
          surveyId,
          maskingKey.questionId
        )
      ) {
        return false
      }
    } else {
      choice = persistedRespondentChoices.find(
        (persistedChoice) =>
          persistedChoice.questionId === maskingKey.questionId
      )
      if (
        isQuestionHiddenByDisplayLogic(
          respondentId,
          surveyId,
          maskingKey.questionId
        )
      ) {
        return false
      }
    }

    return (
      choice?.responseChoiceIds.includes(maskingKey.responseOptionId) ?? true
    )
  }

export const maskingRuleMetByAtLeastOneMaskingKey =
  (
    respondentId: string,
    surveyId: string,
    persistedRespondentChoices: PersistentRespondentChoice[]
  ) =>
  (maskingRule: MaskingRule): boolean => {
    const someResult = maskingRule.maskingKeys.some(
      maskingKeyFoundInPersistedRespondentChoices(
        respondentId,
        surveyId,
        persistedRespondentChoices
      )
    )
    return maskingRule.negated ? !someResult : someResult
  }

export const getClause = (
  maskingRule: MaskingRule[]
): MaskingRuleClause | undefined => {
  return (
    (maskingRule && maskingRule?.find((_, index) => index === 0)?.clause) ||
    undefined
  )
}
